import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

export const useStyles = makeStyles()((theme) => ({
  root: {},
  ul: {
    alignItems: 'stretch',
    padding: 'unset',
    '& button': {
      fontSize: '16px',
      lineHeight: '28px',
      width: '50px',
      height: '50px',
      borderRadius: '12px',
      color: theme.palette.black['40'],
      fontWeight: '400',
      '&:hover': {
        backgroundColor: `${theme.palette.common.white} !important`,
        boxShadow: `0px 8px 20px ${alpha(theme.palette.black['100'], 0.1)} `,
        color: theme.palette.primary.light
      },
      '&[aria-current="true"]': {
        backgroundColor: `${theme.palette.common.white} !important`,
        boxShadow: `0px 8px 20px ${alpha(theme.palette.black['100'], 0.1)} `,
        color: theme.palette.primary.light,
        fontWeight: '500'
      },
      '&:disabled': {
        color: theme.palette.black['20']
      }
    },
    '& div': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.black['20']
    }
  }
}));
