import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

export const useStyles = makeStyles()((theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '12px',
    background: theme.palette.common.white,
    boxShadow: ` 0px 8px 20px ${alpha(theme.palette.black['100'], 0.1)}`,
    cursor: 'pointer',
    border: 'unset'
  },
  disabled: {
    background: theme.palette.black['10']
  }
}));
