import React from 'react';
import loadable from '@loadable/component';
import { createBrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'shared/router/ErrorBoundary';
import { ROUTES } from 'shared/config/routes';
import {
  ACCESSES,
  AccessLayout,
  AuthLayout,
  SidebarLayout,
  RootLayout
} from 'widgets/layouts';

const UiKit = loadable(() => import('../pages/ui-kit'));
const Roles = loadable(() => import('../pages/roles/main'));
const CreateRole = loadable(() => import('../pages/roles/create'));
const EditeRole = loadable(() => import('../pages/roles/edite'));
const WatchRole = loadable(() => import('../pages/roles/watch'));
const Employees = loadable(() => import('../pages/employees'));
const Employee = loadable(() => import('../pages/employee'));
const SignIn = loadable(() => import('../pages/auth/signIn'));
const RecoveryPass = loadable(() => import('../pages/auth/recoveryPass'));
const Verify = loadable(() => import('../pages/auth/verify'));
const CheckSms = loadable(() => import('../pages/auth/checkSms'));
const CreatePass = loadable(() => import('../pages/auth/createPass'));
const PageNotFound = loadable(() => import('../pages/404'));
const PersonalCabinet = loadable(() => import('../pages/personal-cabinet'));
const Complexes = loadable(() => import('../pages/cartoteka/complexes'));
const Houses = loadable(() => import('../pages/cartoteka/houses'));
const Aparts = loadable(() => import('../pages/cartoteka/aparts'));
const Apart = loadable(() => import('../pages/cartoteka/apart'));
const Users = loadable(() => import('../pages/cartoteka/users'));
const User = loadable(() => import('../pages/cartoteka/user'));
const Requests = loadable(() => import('../pages/requests'));
const Request = loadable(() => import('../pages/request'));
const News = loadable(() => import('../pages/quarter/news'));
const CreateNews = loadable(() => import('../pages/quarter/createNews'));
const Quiz = loadable(() => import('../pages/quarter/quiz'));
const CreateQuiz = loadable(() => import('../pages/quarter/createQuiz'));
const QuizResult = loadable(() => import('../pages/quarter/quizResult'));
const Vote = loadable(() => import('../pages/quarter/vote'));
const CreateVote = loadable(() => import('../pages/quarter/createVote'));
const VoteResult = loadable(() => import('../pages/quarter/voteResult'));
const Meters = loadable(() => import('../pages/meters/main'));
const PrivacyPolicy = loadable(() => import('../pages/privacy-policy'));
const CamerasComplexes = loadable(() => import('../pages/security/camerasComplexes'));
const Cameras = loadable(() => import('../pages/security/cameras'));
const FAQ = loadable(() => import('../pages/services/faq'));
const FaqWatch = loadable(() => import('../pages/services/faqWatch'));
const ResidentialComplexes = loadable(() => import('../pages/services/complexes'));
const Contacts = loadable(() => import('../pages/services/contacts'));
const Contact = loadable(() => import('../pages/services/contacts/contact'));

export const router = createBrowserRouter([
  {
    element: <SidebarLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <UiKit />,
        path: ROUTES.UI_KIT.path,
        errorElement: <ErrorBoundary />
      },
      {
        element: <AccessLayout access={ACCESSES.roles} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <Roles />,
            path: ROUTES.ROLES.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <CreateRole />,
            path: ROUTES.CREATE_ROLE.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <EditeRole />,
            path: ROUTES.EDITE_ROLE.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <WatchRole />,
            path: ROUTES.WATCH_ROLE.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.employees} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <Employees />,
            path: ROUTES.EMPLOYEES.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <Employee />,
            path: ROUTES.EMPLOYEE.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.client} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <Users />,
            path: ROUTES.USERS.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <User />,
            path: ROUTES.USER.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.house} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <Complexes />,
            path: ROUTES.COMPLEXES.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <Houses />,
            path: ROUTES.HOUSES.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <Aparts />,
            path: ROUTES.APARTS.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <Apart />,
            path: ROUTES.APART.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.request} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <Requests />,
            path: ROUTES.REQUESTS.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <Request />,
            path: ROUTES.REQUEST.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.meter} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <Meters />,
            path: ROUTES.METERS.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.news} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <News />,
            path: ROUTES.NEWS.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <CreateNews />,
            path: ROUTES.CREATE_NEWS.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <CreateNews />,
            path: ROUTES.EDITE_NEWS.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.quiz} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <Quiz />,
            path: ROUTES.QUIZ.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <CreateQuiz />,
            path: ROUTES.CREATE_QUIZ.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <CreateQuiz />,
            path: ROUTES.EDITE_QUIZ.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <QuizResult />,
            path: ROUTES.RESULT_QUIZ.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.quiz} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <Vote />,
            path: ROUTES.VOTES.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <CreateVote />,
            path: ROUTES.CREATE_VOTE.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <CreateVote />,
            path: ROUTES.EDITE_VOTE.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <VoteResult />,
            path: ROUTES.RESULT_VOTE.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.camera} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <CamerasComplexes />,
            path: ROUTES.CAMERAS_COMPLEXES.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <Cameras />,
            path: ROUTES.CAMERAS.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.faq} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <FAQ />,
            path: ROUTES.FAQ.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <FaqWatch />,
            path: ROUTES.FAQ_WATCH.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <AccessLayout access={ACCESSES.contact} />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <ResidentialComplexes />,
            path: ROUTES.CONTACTS_COMPLEXES.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <Contacts />,
            path: ROUTES.CONTACTS.path,
            errorElement: <ErrorBoundary />
          },
          {
            element: <Contact />,
            path: ROUTES.CONTACT.path,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        element: <PersonalCabinet />,
        path: ROUTES.PERSONAL_CABINET.path,
        errorElement: <ErrorBoundary />
      },
      {
        element: <RootLayout />,
        path: '*'
      },
      {
        element: <PageNotFound />,
        path: ROUTES.NOT_FOUND_PAGE.path
      }
    ]
  },
  {
    element: <AuthLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <SignIn />,
        path: ROUTES.SIGN_IN.path,
        errorElement: <ErrorBoundary />
      },
      {
        element: <Verify />,
        path: ROUTES.VERIFY.path,
        errorElement: <ErrorBoundary />
      },
      {
        element: <CheckSms />,
        path: ROUTES.CHECK_SMS.path,
        errorElement: <ErrorBoundary />
      },
      {
        element: <CreatePass />,
        path: ROUTES.CREATE_PASS.path,
        errorElement: <ErrorBoundary />
      },
      {
        element: <RecoveryPass />,
        path: ROUTES.RECOVERY_PASS.path,
        errorElement: <ErrorBoundary />
      },
      {
        element: <PrivacyPolicy />,
        path: ROUTES.PRIVACY_POLICY.path,
        errorElement: <ErrorBoundary />
      }
    ]
  }
]);
