import React from 'react';
import MUITableRow from '@mui/material/TableRow';
import { useStyles } from './TableRow.styles';

interface IProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const TableBodyRow = ({ children, onClick }: IProps) => {
  const { classes, cx } = useStyles();
  const handleClick = () => {
    if (!window.getSelection()?.toString() && onClick) {
      onClick();
    }
  };

  return (
    <MUITableRow
      className={cx(onClick && classes.actionRow)}
      classes={{ root: classes.bodyRow }}
      onClick={handleClick}
    >
      {children}
    </MUITableRow>
  );
};
