import EmployeesIcon from '@mui/icons-material/PersonPinRounded';
import AppsIcon from '@mui/icons-material/Apps';
import { HowToVote } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SecureIcon from '@mui/icons-material/Security';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { ROUTES } from 'shared/config/routes';
import { ACCESSES } from 'widgets/layouts/access/accessesList';

export const navigationList = [
  {
    id: 'employees',
    title: 'Сотрудники',
    Icon: EmployeesIcon,
    link: ROUTES.EMPLOYEES.path,
    accessKey: ACCESSES.employees
  },
  {
    id: 'cartoteka',
    title: 'Картотека',
    Icon: AppsIcon,
    subNavigation: [
      {
        title: 'Пользователи',
        link: ROUTES.USERS.path,
        accessKey: ACCESSES.client
      },
      {
        title: 'Объекты ЖК',
        link: ROUTES.COMPLEXES.path,
        accessKey: ACCESSES.house
      }
    ]
  },
  {
    id: 'ourQuarter',
    title: 'Наш квартал',
    Icon: HowToVote,
    subNavigation: [
      {
        title: 'Новости',
        link: ROUTES.NEWS.path,
        accessKey: ACCESSES.news
      },
      {
        title: 'Опросы',
        link: ROUTES.QUIZ.path,
        accessKey: ACCESSES.quiz
      }
      // {
      //   title: 'Голосования',
      //   link: ROUTES.VOTES.path,
      //   accessKey: ACCESSES.quiz
      // }
    ]
  },
  {
    id: 'request',
    title: 'Заявки',
    Icon: DescriptionIcon,
    link: ROUTES.REQUESTS.path,
    accessKey: ACCESSES.request
  },
  {
    id: 'meter',
    title: 'Счетчики',
    Icon: WatchLaterIcon,
    link: ROUTES.METERS.path,
    accessKey: ACCESSES.meter
  },
  {
    id: 'security',
    title: 'Безопасность',
    Icon: SecureIcon,
    subNavigation: [
      {
        title: 'Камеры',
        link: ROUTES.CAMERAS_COMPLEXES.path,
        accessKey: ACCESSES.camera
      },
      {
        title: 'Роли',
        link: ROUTES.ROLES.path,
        accessKey: ACCESSES.roles
      }
    ]
  },
  {
    id: 'service',
    title: 'Сервис и услуги',
    Icon: SettingsApplicationsIcon,
    subNavigation: [
      {
        title: 'FAQ',
        link: ROUTES.FAQ.path,
        accessKey: ACCESSES.faq
      },
      {
        title: 'Полезные контакты',
        link: ROUTES.CONTACTS_COMPLEXES.path,
        accessKey: ACCESSES.contact
      }
    ]
  }
];
