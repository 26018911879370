import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditQuery, IResponseWithPagination } from 'typings/app';
import {
  IContact,
  IContactEditForm,
  IContactForm,
  IContactQueryPaginationParams
} from 'typings/contacts';
import { customFetchBase } from '../fetchBase';

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['Contacts'],
  endpoints: (build) => ({
    getContacts: build.query<
      IResponseWithPagination<IContact>,
      IContactQueryPaginationParams
    >({
      query: (params) => ({
        url: '/contact',
        params
      }),
      providesTags: ['Contacts']
    }),
    getOneContact: build.query<IContact, number>({
      query: (id) => ({
        url: `/contact/${id}`
      }),
      providesTags: ['Contacts']
    }),
    createContact: build.mutation<null, IContactForm>({
      query: (body) => ({
        url: '/contact',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Contacts']
    }),
    updateContact: build.mutation<null, IEditQuery<IContactEditForm>>({
      query: (data) => ({
        url: `/contact/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Contacts']
    }),
    deleteContact: build.mutation<null, number>({
      query: (id) => ({
        url: `/contact/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Contacts']
    })
  })
});

export const {
  useCreateContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation,
  useGetContactsQuery,
  useGetOneContactQuery
} = contactsApi;
