import React from 'react';
import { ReactComponent as ToastSuccess } from 'shared/assets/images/toast_success.svg';
import { ReactComponent as ToastError } from 'shared/assets/images/toast_error.svg';
import { ReactComponent as ToastWarning } from 'shared/assets/images/toast_warning.svg';
import Typography from '@mui/material/Typography';
import { useStyles } from './Toast.styles';

interface IProps {
  type: 'error' | 'success' | 'warning';
  title: string;
  subTitle?: string;
}

export const Toast = ({ type, subTitle, title }: IProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      {type === 'success' && <ToastSuccess className={classes.icon} />}
      {type === 'error' && <ToastError className={classes.icon} />}
      {type === 'warning' && <ToastWarning className={classes.icon} />}
      <div className={classes.desc}>
        <Typography variant="smallButton" color="black.90" fontWeight={500}>
          {title}
        </Typography>
        <Typography variant="tabBar" color="black.40" fontWeight={500}>
          {subTitle}
        </Typography>
      </div>
    </div>
  );
};
