import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IBills,
  IMeter,
  IMeterForm,
  IMeterParsed,
  IMeters,
  IMetersResponse,
  IPostMeterForm,
  IUpdateMeterForm
} from 'typings/meter';
import { IEditQuery, IQueryPaginationParams, IResponseWithPagination } from 'typings/app';
import { customFetchBase } from '../fetchBase';

export const meterApi = createApi({
  reducerPath: 'meterApi',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 0,
  tagTypes: ['Bills', 'Meter'],
  endpoints: (build) => ({
    getMeters: build.query<IMetersResponse, IQueryPaginationParams>({
      query: (params) => ({
        url: '/meter',
        params
      }),
      providesTags: ['Meter']
    }),
    getBills: build.query<IResponseWithPagination<IBills>, IQueryPaginationParams>({
      query: (params) => ({
        url: '/meter/bill',
        params
      }),
      providesTags: ['Bills']
    }),
    getBillMeters: build.query<IResponseWithPagination<IMeters>, IQueryPaginationParams>({
      query: (params) => ({
        url: `/meter/bill/${String(params.id)}`,
        params
      }),
      providesTags: ['Meter']
    }),
    getMeter: build.query<IResponseWithPagination<IMeter>, IQueryPaginationParams>({
      query: ({ id, page, limit }) => ({
        url: `/meter/apart/${String(id)}`,
        params: { page, limit }
      }),
      providesTags: ['Meter']
    }),
    postMeter: build.mutation<IResponseWithPagination<IMeter>, IPostMeterForm>({
      query: (body) => ({
        url: '/meter',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Meter']
    }),
    postMeters: build.mutation<IMeterParsed, IMeterForm>({
      query: ({ file }) => ({
        url: '/meter/load',
        method: 'POST',
        body: file,
        formData: true
      }),
      invalidatesTags: ['Meter']
    }),
    updateMeters: build.mutation<null, IEditQuery<IUpdateMeterForm>>({
      query: (data) => ({
        url: `/meter/${String(data.id)}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Meter']
    }),
    deleteMeter: build.mutation<null, number>({
      query: (id) => ({
        url: `/meter/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Meter']
    })
  })
});

export const {
  useGetMetersQuery,
  usePostMetersMutation,
  useGetMeterQuery,
  usePostMeterMutation,
  useUpdateMetersMutation,
  useDeleteMeterMutation,
  useGetBillsQuery,
  useGetBillMetersQuery
} = meterApi;
