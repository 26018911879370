import React from 'react';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

export default function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      {isRouteErrorResponse(error) && error.data?.message && <p>{error.data.message}</p>}
    </>
  );
}
