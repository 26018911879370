import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ width?: string }>()((theme, props) => ({
  headCell: {
    border: 'unset',
    color: theme.palette.primary.light,
    width: props?.width,
    maxWidth: props?.width
  },
  cellInner: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  cellIcon: {
    cursor: 'pointer',
    transition: 'all 0.1s linear',
    '&:hover': {
      color: theme.palette.black['60']
    }
  }
}));
