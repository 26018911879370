import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditQuery, IQueryPaginationParams } from 'typings/app';
import { IHouse, IHouseForm, IHouseList, IHousesList, IHouseSelect } from 'typings/houses';
import { customFetchBase } from '../fetchBase';

export const housesAPI = createApi({
  reducerPath: 'housesAPI',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['Houses'],
  endpoints: (build) => ({
    getComplexHouses: build.query<IHousesList, IQueryPaginationParams>({
      query: ({ id, ...params }) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `/complex/${id}/house/`,
        params
      }),
      providesTags: ['Houses']
    }),
    getHouse: build.query<IHouse, number>({
      query: (id) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `/house/${id}`
      }),
      providesTags: ['Houses']
    }),
    getHouses: build.query<IHouseList, IQueryPaginationParams>({
      query: ({ page, limit }) => ({
        url: '/house',
        params: { page, limit }
      }),
      providesTags: ['Houses']
    }),
    createHouse: build.mutation<null, IHouseForm>({
      query: (body) => ({
        url: '/house/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Houses']
    }),
    updateHouse: build.mutation<null, IEditQuery<IHouseForm>>({
      query: (data) => ({
        url: `/house/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Houses']
    }),
    deleteHouse: build.mutation<null, number>({
      query: (id) => ({
        url: `/house/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Houses']
    }),
    getHouseSelect: build.query<IHouseSelect[], number | void>({
      query: (complexId) => ({
        url: '/house/select',
        params: { complexId }
      })
    })
  })
});

export const {
  useCreateHouseMutation,
  useGetHouseQuery,
  useDeleteHouseMutation,
  useGetComplexHousesQuery,
  useUpdateHouseMutation,
  useGetHousesQuery,
  useGetHouseSelectQuery
} = housesAPI;
