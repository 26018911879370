import React, { ComponentType, MouseEventHandler, ReactElement, useMemo } from 'react';
import {
  FixedSizeList as _FixedSizeList,
  FixedSizeListProps,
  ListChildComponentProps
} from 'react-window';
import { useStyles } from './SearchSelect.styles';

const FixedSizeList = _FixedSizeList as ComponentType<FixedSizeListProps>;

const Row = ({ index, style, data }: ListChildComponentProps) => (
  <div key={data[index].key} style={style} {...data[index].props.option}>
    {data[index]}
  </div>
);

export const ListboxComponent = ({
  children,
  onMouseDown,
  loading
}: {
  children: ReactElement[];
  onMouseDown?: MouseEventHandler;
  loading?: boolean;
}) => {
  const { classes, cx } = useStyles();
  const heightListBox = useMemo(() => {
    if (children.length < 6) {
      return children.length * 50;
    }

    if (loading) {
      return 50;
    }

    return 300;
  }, [loading, children.length]);

  return (
    <div onMouseDown={onMouseDown}>
      <FixedSizeList
        height={heightListBox}
        itemCount={children.length}
        itemSize={50}
        width="100%"
        itemData={children}
        className={cx(classes.list)}
      >
        {Row}
      </FixedSizeList>
    </div>
  );
};
