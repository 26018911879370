import React from 'react';
import MUIButton, { ButtonProps } from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import useStyles from './Button.styles';

interface IProps extends ButtonProps {
  loading?: boolean;
  color?: 'primary' | 'secondary';
  component?: string;
}

interface ILoadingProps {
  disabled: boolean;
  startIcon: JSX.Element;
}

export const Button: React.FC<IProps> = (props: IProps) => {
  const {
    children,
    className,
    size = 'medium',
    color = 'primary',
    variant = 'contained',
    loading,
    disabled
  } = props;
  const { classes, cx } = useStyles();
  let loadingProps: ILoadingProps | Record<string, never> = {};

  if (loading) {
    const loadingSize = size === 'large' ? 16 : 14;
    loadingProps = {
      startIcon: <CircularProgress className={classes.loading} size={loadingSize} />,
      disabled: true
    };
  }

  return (
    <MUIButton
      {...props}
      className={cx(
        classes.button,
        classes[size],
        classes[color],
        variant === 'contained' && classes.contained,
        variant === 'outlined' && classes.outlined,
        disabled && classes.disabled,
        className
      )}
      {...loadingProps}
    >
      {children}
    </MUIButton>
  );
};
