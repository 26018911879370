import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  menu: {
    '& .MuiPaper-root': {
      minWidth: '465px',
      top: '208px !important',
      borderRadius: '10px'
    }
  },
  modal: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '10px',
    outline: 'none'
  },
  body: {
    position: 'relative',
    padding: '40px 30px'
  },
  closeIcon: {
    position: 'absolute',
    right: '24px',
    top: '24px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    marginBottom: '10px'
  },
  titleIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    borderRadius: '100%',
    background: theme.palette.error.light
  },
  titleIcon: {
    color: theme.palette.error.main
  },
  bottom: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
    padding: '20px',
    borderTop: `1px solid ${theme.palette.black['10']}`
  },
  button: {
    minWidth: '140px'
  },
  text: {
    wordBreak: 'break-word'
  }
}));
