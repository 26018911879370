import React from 'react';
import { createRoot } from 'react-dom/client';

type RootElement = React.ReactNode;

interface IBootstrapConfig {
  rootElement: RootElement;
  selector: string;
}

const injectExtension = (element: RootElement, selector: string) => {
  const elementInDOM = document.querySelector(selector);

  if (elementInDOM != null) {
    const root = createRoot(elementInDOM);
    root.render(element);
  }
};

export const bootstrap = (config: IBootstrapConfig) => injectExtension(config.rootElement, config.selector);
