import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useStyles } from './Tooltip.styles';

interface IProps extends TooltipProps {
  disablePortal?: boolean;
}

export const LightTooltip = (props: IProps) => {
  const { title, placement, disablePortal = true } = props;
  const typographyTitle = (
    <Typography variant="button" fontWeight="400">
      {title}
    </Typography>
  );
  const { classes } = useStyles();
  return (
    <Tooltip
      {...props}
      classes={{ ...classes }}
      title={typographyTitle}
      PopperProps={{
        disablePortal,
        popperOptions: {
          placement: placement ?? 'bottom'
        }
      }}
    />
  );
};
