import { IUser } from 'typings/auth';
import { AccessesValuesType } from 'typings/accesses';
import { ACCESSES_OPERATION } from './accessesList';

export const isAvailableCatalog = (
  user: IUser,
  catalog?: AccessesValuesType
): boolean => {
  return (
    user?.role?.accesses.includes('god') ||
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    user?.role?.accesses.includes(`${catalog}_${ACCESSES_OPERATION.get}`)
  );
};
