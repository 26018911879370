import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

interface IReturnProps {
  currentPage: number;
  limit: number;
  handlePaginationChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  setCurrentPage: (value: number) => void;
  setLastPage: (value: number) => void;
}

export const usePagination = (initialPage: number, limit: number = 10): IReturnProps => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams({
    page: initialPage > 0 ? initialPage.toString() : '1'
  });
  const [page, setPage] = React.useState<number>(Number(searchParams.get('page')));

  useEffect(() => {
    setPage(Number(searchParams.get('page')));
  }, [location]);

  const setCurrentPage = (value: number): void => {
    setPage(value);
    searchParams.set('page', value.toString());
    setSearchParams(searchParams);
  };

  const setLastPage = (value: number): void => {
    setPage(value);
    searchParams.set('page', value.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setCurrentPage(value);
  };

  return {
    currentPage: page,
    setCurrentPage,
    setLastPage,
    limit,
    handlePaginationChange
  };
};
