import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditQuery, IQueryPaginationParams, IResponseWithPagination } from 'typings/app';
import { IClient, IClientForm } from 'typings/clients';
import { customFetchBase } from '../fetchBase';

export const clientAPI = createApi({
  reducerPath: 'clientAPI',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['Client'],
  endpoints: (build) => ({
    getClients: build.query<IResponseWithPagination<IClient>, IQueryPaginationParams>({
      query: (params) => ({
        url: '/client/',
        params
      }),
      providesTags: ['Client']
    }),
    getClient: build.query<IClient, number>({
      query: (id) => ({
        url: `/client/${id}`
      }),
      providesTags: ['Client']
    }),
    getClientSearch: build.query<
      IResponseWithPagination<IClient>,
      IQueryPaginationParams
    >({
      query: (params) => ({
        url: '/client/search',
        params
      }),
      providesTags: ['Client']
    }),
    createClient: build.mutation<null, IClientForm>({
      query: (body) => ({
        url: '/client/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Client']
    }),
    updateClient: build.mutation<null, IEditQuery<IClientForm>>({
      query: (data) => ({
        url: `/client/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Client']
    }),
    deleteClient: build.mutation<null, number>({
      query: (clientId) => ({
        url: `/apart-client/client/${clientId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Client']
    })
  })
});

export const {
  useGetClientsQuery,
  useGetClientQuery,
  useCreateClientMutation,
  useDeleteClientMutation,
  useUpdateClientMutation,
  useGetClientSearchQuery
} = clientAPI;
