import React from 'react';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Control, useController } from 'react-hook-form';
import { IOption } from 'typings/app';
import Typography from '@mui/material/Typography';
import { useStyles } from './Select.styles';

interface IProps extends BaseTextFieldProps {
  options: IOption[];
  control: Control<any>;
  label: string;
  name: string;
  defaultValue?: string;
  className?: string;
}

export const Select = ({
  label,
  options,
  control,
  name,
  defaultValue,
  disabled,
  className,
  ...rest
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const { classes, cx } = useStyles();
  const {
    field: { value, onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  });
  return (
    <div>
      <TextField
        select
        disabled={disabled}
        value={value}
        onChange={onChange}
        label={label}
        classes={{ root: classes.textFieldRoot }}
        SelectProps={{
          open,
          onOpen: (e) => {
            setAnchorEl(e.target as HTMLElement);
            setOpen(true);
          },
          onClose: () => setOpen(false),
          classes: {
            select: cx(
              classes.select,
              classes.background,
              className,
              invalid && classes.error,
              disabled && classes.disabledSelect
            ),
            icon: cx(classes.icon, classes.color, disabled && classes.disabledLabel)
          },
          IconComponent: KeyboardArrowDownIcon,
          MenuProps: {
            sx: { maxWidth: anchorEl?.offsetWidth },
            classes: {
              root: classes.menuRoot,
              paper: cx(
                classes.menuPaper,
                classes.background,
                invalid && classes.errorMenuPaper
              )
            }
          }
        }}
        InputProps={{ classes: { root: classes.root } }}
        InputLabelProps={{
          classes: {
            root: cx(classes.inputLabel, disabled && classes.inputLabelDisabled),
            shrink: classes.inputLabelShrink
          },
          shrink: !!value || open
        }}
        {...rest}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.id}
            value={String(option.value)}
            className={cx(classes.option, value === option.value && classes.activeOption)}
            disabled={option.value === 'empty'}
          >
            {option.prefix}
            <span className={classes.optionLabel}>{option.label}</span>
          </MenuItem>
        ))}
      </TextField>
      {error && (
        <Typography variant="formError" component="div" color="error.main">
          {error?.message}
        </Typography>
      )}
    </div>
  );
};
