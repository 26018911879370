import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<
  { startIcon?: boolean; endIcon?: boolean; hidden?: boolean },
  'primary' | 'secondary'
>()((theme, props, classes) => ({
  wrapper: {
    display: props.hidden ? 'none !important' : 'block'
  },
  root: {
    borderRadius: '12px',
    fontSize: '16px',
    lineHeight: '28px',
    paddingLeft: props?.startIcon ? '24px' : '0',
    paddingRight: props?.endIcon ? '24px' : '0',
    '& input': {
      paddingTop: '18px',
      paddingBottom: '18px',
      paddingLeft: props?.startIcon ? '34px' : '24px',
      paddingRight: props?.endIcon ? '34px' : '24px',
      height: '28px'
    },

    [`&.${classes.primary}`]: {
      backgroundColor: `${theme.palette.black['3']}`,
      color: theme.palette.black['90']
    },

    [`&.${classes.secondary}`]: {
      backgroundColor: 'transparent',
      boxShadow: `0px 8px 20px ${theme.palette.black['10']}`
    }
  },
  rootFocused: {
    [`&.${classes.primary}`]: {
      backgroundColor: theme.palette.black['5'],
      outline: `1px solid ${theme.palette.black['40']}`
    },
    [`&.${classes.secondary}`]: {}
  },
  inputLabel: {
    display: props?.hidden ? 'none !important' : 'block',
    left: props?.startIcon ? '46px' : '12px',
    fontSize: '16px',
    lineHeight: '28px',
    color: `${theme.palette.black['60']} !important`,
    transform: 'translate(12px, 11px) scale(1)'
  },
  inputLabelShrink: {
    transform: 'translate(12px, 6px) scale(0.75)'
  },
  error: {
    backgroundColor: `${theme.palette.error.light} !important`,
    outline: `1px solid ${theme.palette.error.main} !important`
  },
  adornment: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto !important',
    height: 'auto',
    left: props?.startIcon ? '24px' : 'unset',
    right: props?.endIcon ? '24px' : 'unset',
    color: theme.palette.black['60']
  },
  primary: {},
  secondary: {}
}));
