import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, props, classes) => ({
  textFieldRoot: {
    display: 'flex'
  },
  root: {
    borderRadius: 'unset',
    width: '100%',
    minWidth: '150px',
    '& fieldset': {
      border: 'unset'
    }
  },
  inputLabel: {
    display: 'block',
    left: '12px',
    fontSize: '16px',
    lineHeight: '28px',
    color: `${theme.palette.black['60']} !important`,
    transform: 'translate(12px, 11px) scale(1)'
  },
  inputLabelDisabled: {
    color: `${theme.palette.black['20']} !important`
  },
  inputLabelShrink: {
    transform: 'translate(12px, 6px) scale(0.75)'
  },
  labelRoot: {
    top: '50%',
    left: '24px',
    transform: 'translate(0, -50%)',
    maxWidth: 'calc(100% - 48px)'
  },
  select: {
    height: '28px',
    padding: '21px 40px 6px 24px !important',
    borderRadius: '12px !important',
    [theme.breakpoints.down('desktop')]: {
      height: '22px'
    }
  },
  menuRoot: {
    top: '5px',
    maxHeight: '230px',
    padding: 0,
    '& ul': {
      padding: '0px'
    }
  },
  menuPaper: {
    boxShadow: 'unset',
    borderRadius: '12px',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '100px',
      border: '2px solid transparent',
      backgroundColor: theme.palette.black['20'],
      backgroundClip: 'content-box'
    },
    '&::-webkit-scrollbar-track': {
      margin: '5px',
      background: 'transparent'
    },
    '::-webkit-scrollbar-corner': {
      background: 'transparent'
    }
  },
  errorMenuPaper: {
    outline: `1px solid ${theme.palette.error.main} !important`
  },
  icon: {
    right: '24px'
  },
  color: {
    color: `${theme.palette.black['60']} !important`
  },
  background: {
    backgroundColor: `${theme.palette.black['3']} !important`
  },
  hidden: {
    opacity: 0
  },
  error: {
    backgroundColor: `${theme.palette.error.light} !important`,
    outline: `1px solid ${theme.palette.error.main} !important`
  },
  option: {
    padding: '11px 24px',
    color: theme.palette.black['90'],
    backgroundColor: 'transparent !important',
    lineHeight: '28px',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: `${theme.palette.black['10']} !important`
    },
    '&:not(:first-child)': {
      borderTop: `1px solid ${theme.palette.black['10']}`
    },
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  optionLabel: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  activeOptionLabel: {
    whiteSpace: 'nowrap',
    paddingRight: '40px'
  },
  activeOption: {
    backgroundColor: `${theme.palette.black['10']} !important`,
    pointerEvents: 'none',
    color: theme.palette.black['60']
  },
  disabledSelect: {
    color: `${theme.palette.black['20']} !important`
  },
  disabledLabel: {
    color: `${theme.palette.black['20']} !important`
  }
}));
