import React from 'react';
import { useController, Control } from 'react-hook-form';
import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import { ReactComponent as CheckboxActiveIcon } from 'shared/assets/images/checkbox_active.svg';
import { ReactComponent as CheckboxDefaultIcon } from 'shared/assets/images/checkbox_default.svg';
import { ReactComponent as CheckboxDisabledIcon } from 'shared/assets/images/checkbox_disabled.svg';
import { ReactComponent as CheckboxCheckedDisabledIcon } from 'shared/assets/images/checkbox_checked_disabled.svg';
import { useStyles } from './Checkbox.styles';

interface IProps extends CheckboxProps {
  name: string;
  control: Control<any>;
  defaultValue?: string;
}

export const Checkbox = ({ control, name, defaultValue, ...rest }: IProps) => {
  const { field } = useController({
    name,
    control,
    defaultValue
  });
  const { classes } = useStyles();
  return (
    <MUICheckbox
      checked={!!field.value}
      onChange={field.onChange}
      classes={{ root: classes.root }}
      checkedIcon={
        rest.disabled ? <CheckboxCheckedDisabledIcon /> : <CheckboxActiveIcon />
      }
      icon={rest.disabled ? <CheckboxDisabledIcon /> : <CheckboxDefaultIcon />}
      {...rest}
    />
  );
};
