export const ACCESSES = {
  roles: 'role',
  employees: 'user',
  house: 'house',
  client: 'client',
  request: 'request',
  news: 'news',
  quiz: 'quiz',
  meter: 'meter',
  bill: 'bill',
  camera: 'camera',
  faq: 'faq',
  contact: 'contact'
} as const;

export const ACCESSES_OPERATION = {
  get: 'get',
  update: 'update',
  post: 'post',
  delete: 'delete'
};
