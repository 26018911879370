import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { Toast } from './ui/Toast';

const initialOptions: ToastOptions = {
  autoClose: 3000,
  closeButton: <CloseIcon />,
  type: toast.TYPE.ERROR,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_RIGHT,
  pauseOnHover: true,
  icon: false
};

interface IToastProps {
  type: 'error' | 'success' | 'warning';
  title: string;
  subTitle?: string;
}

export const showToast = (data: IToastProps, options?: ToastOptions) => {
  toast(<Toast {...data} />, {
    style: { fontFamily: 'Pragmatica' },
    ...initialOptions,
    ...options
  });
};

export const toastSuccessHandler = (
  subTitle: string,
  options?: ToastOptions,
  title = 'Успешно'
) => {
  toast(<Toast type={'success'} title={title} subTitle={subTitle} />, {
    ...initialOptions,
    ...options,
    style: { fontFamily: 'Pragmatica' }
  });
};

export const toastErrorHandler = (err: any) => {
  if (
    (err.status === 400 || err.status === 404 || err.status === 422) &&
    err?.data?.message
  ) {
    showToast({
      type: 'error',
      title: 'Ошибка',
      subTitle: err?.data?.message
    });
  }
};

export const toastNotification = (
  title: string,
  subTitle: string,
  onClick: () => void
) => {
  showToast(
    {
      type: 'warning',
      title,
      subTitle
    },
    {
      style: { cursor: 'pointer' },
      autoClose: false,
      onClick,
      closeButton: (params) => (
        <CloseIcon
          style={{ cursor: 'pointer' }}
          onClick={(event) => {
            event.stopPropagation();
            params.closeToast(event as unknown as React.MouseEvent<HTMLElement>);
          }}
        />
      )
    }
  );
};
