import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '22px 0'
  },
  header: {
    width: '100%',
    height: '41px',
    maxWidth: '1920px'
  },
  logo: {
    marginLeft: '20%',
    height: '100%',
    width: 'auto'
  }
}));
