import React, { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ExitIcon from '@mui/icons-material/ExitToApp';
import { ROUTE } from 'shared/config/routes';
import { NavigationItem } from './navigationItem/NavigationItem';
import { useStyles } from './Navigation.styles';
import { navigationList } from '../navigationList';

interface IProps {
  logoutHandler: () => void;
}

export const Navigation = ({ logoutHandler }: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState<string | false>(false);

  return (
    <nav className={classes.navigation}>
      <div>
        {navigationList.map(({ title, link, Icon, id, subNavigation, accessKey }) => (
          <NavigationItem
            key={title}
            title={title}
            link={link}
            Icon={Icon}
            id={id}
            subNavigation={subNavigation}
            expanded={expanded}
            setExpanded={setExpanded}
            access={accessKey}
          />
        ))}
      </div>
      <div>
        <NavigationItem
          link={ROUTE.PERSONAL_CABINET}
          id={'personal'}
          expanded={expanded}
          setExpanded={setExpanded}
          title="Личный кабинет"
          Icon={PersonIcon}
        />
        <NavigationItem onClick={logoutHandler} title="Выйти" Icon={ExitIcon} dark />
      </div>
    </nav>
  );
};
