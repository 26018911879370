import { createApi } from '@reduxjs/toolkit/query/react';
import { IRole, IRoleBackend } from 'typings/roles';
import {
  IQueryParams,
  IQueryPaginationParams,
  IEditQuery,
  IMessageResponse
} from 'typings/app';
import { customFetchBase } from '../fetchBase';

export const rolesAPI = createApi({
  reducerPath: 'rolesAPI',
  baseQuery: customFetchBase,
  tagTypes: ['Roles', 'Role'],
  endpoints: (build) => ({
    getAllRoles: build.query<IRoleBackend, void>({
      query: () => ({ url: '/role?limit=100' }),
      providesTags: ['Roles']
    }),
    getRoles: build.query<IRoleBackend, IQueryPaginationParams>({
      query: (params) => ({
        url: '/role',
        params
      }),
      providesTags: ['Roles']
    }),
    getRole: build.query<IRole, IQueryParams>({
      query: (data) => ({
        url: `/role/${data.id}`
      }),
      providesTags: ['Role']
    }),
    createRole: build.mutation<IMessageResponse, Omit<IRole, 'id'>>({
      query: (data) => ({
        url: 'role',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Roles']
    }),
    updateRole: build.mutation<IRole, IEditQuery<Omit<IRole, 'id'>>>({
      query: (data) => ({
        url: `role/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Roles', 'Role']
    }),
    deleteRole: build.mutation<IMessageResponse, IQueryParams>({
      query: (data) => ({
        url: `/role/${data.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Roles']
    })
  })
});

export const {
  useGetAllRolesQuery,
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useGetRoleQuery
} = rolesAPI;
