import React from 'react';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MUIModal from '@mui/material/Modal';
import { Button } from 'shared/ui';
import { IModalProps } from './Modal';
import { useStyles } from './Modal.styles';

interface IProps extends Omit<IModalProps, 'children' | 'title'> {
  text: string;
  handleSubmit: any;
  isLoading: boolean;
}

export const DeleteModal = ({
  text,
  isOpen,
  onClose,
  handleSubmit,
  isLoading
}: IProps) => {
  const { classes } = useStyles();
  return (
    <MUIModal open={isOpen} onClose={onClose}>
      <div className={classes.modal}>
        <div className={classes.body}>
          <IconButton
            className={classes.closeIcon}
            onClick={onClose}
            disabled={isLoading}
            disableTouchRipple
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.title}>
            <div className={classes.titleIconWrapper}>
              <DeleteIcon className={classes.titleIcon} />
            </div>
            <Typography variant="modalTitle" color="black.90">
              Удаление
            </Typography>
          </div>
          <Typography
            variant="formMedium"
            color="black.40"
            fontWeight={400}
            className={classes.text}
          >
            {text}
          </Typography>
        </div>

        <div className={classes.bottom}>
          <Button color="secondary" onClick={onClose} disabled={isLoading}>
            Отмена
          </Button>
          <Button onClick={handleSubmit} loading={isLoading}>
            Удалить
          </Button>
        </div>
      </div>
    </MUIModal>
  );
};
