import React from 'react';
import BreadcrumbsMUI, { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { useStyles } from './Breadcrumbs.styles';

interface ILinks {
  title: string;
  path: string;
}

interface IProps extends BreadcrumbsProps {
  links: ILinks[];
}

export const Breadcrumbs = (props: IProps) => {
  const { links } = props;
  const { classes } = useStyles();

  return (
    <BreadcrumbsMUI {...props} className={classes.crumbs}>
      {links?.map(({ title, path }, index) => {
        if (links?.length === index + 1) {
          return (
            <Typography key={title} className={classes.title}>
              {title}
            </Typography>
          );
        }

        return (
          <Link
            component={NavLink}
            to={path}
            key={path}
            underline="hover"
            color="inherit"
            className={classes.link}
          >
            {title}
          </Link>
        );
      })}
    </BreadcrumbsMUI>
  );
};
