import React from 'react';
import MUIModal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Button } from 'shared/ui';
import { useStyles } from './Modal.styles';
import { IModalProps } from './Modal';

export interface IProps extends IModalProps {
  children: React.ReactNode;
  handleSubmit: any;
  isLoading?: boolean;
}
export const CreateModal = ({
  isOpen,
  onClose,
  children,
  title,
  handleSubmit,
  isLoading
}: IProps) => {
  const { classes } = useStyles();
  return (
    <MUIModal open={isOpen} onClose={onClose}>
      <div className={classes.modal}>
        <div className={classes.body}>
          <IconButton
            className={classes.closeIcon}
            onClick={onClose}
            disabled={isLoading}
            disableTouchRipple
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.title}>
            <Typography variant="modalTitle" color="black.90">
              {title}
            </Typography>
          </div>
          {children}
        </div>
        <div className={classes.bottom}>
          <Button color="secondary" onClick={onClose} disabled={isLoading}>
            Отмена
          </Button>
          <Button onClick={handleSubmit} disabled={isLoading} loading={isLoading}>
            Сохранить
          </Button>
        </div>
      </div>
    </MUIModal>
  );
};
