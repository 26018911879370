import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { getUserInfo } from 'store/auth';
import { AccessesValuesType } from 'typings/accesses';
import { ROUTES } from 'shared/config/routes';
import { isAvailableCatalog } from './isAvailableCatalog';
import { JWTToken } from '../../../shared/utils';

interface IProps {
  access: AccessesValuesType;
}

export const AccessLayout = ({ access }: IProps) => {
  const navigate = useNavigate();
  const user = useSelector(getUserInfo);
  const [isInitPage, setInitPage] = useState<boolean>(false);

  useEffect(() => {
    const token = JWTToken.getAccess();
    if (!token) {
      navigate(ROUTES.SIGN_IN.path);
    }
  }, []);

  useEffect(() => {
    if (!isAvailableCatalog(user, access)) {
      navigate(ROUTES.NOT_FOUND_PAGE.path);
    }

    setInitPage(true);
  }, [user, access]);
  return isInitPage ? <Outlet /> : null;
};
