import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

export const useStyles = makeStyles()((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.black['90'],
    borderRadius: '6px',
    boxShadow: `0px 8px 20px ${alpha(theme.palette.black['100'], 0.1)} `,
    padding: '1px 10px',
    letterSpacing: 'unset',
    margin: 'unset',
    whiteSpace: 'normal',
    maxHeight: '500px',
    maxWidth: '365px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '100px',
      border: '2px solid transparent',
      backgroundColor: theme.palette.black['20'],
      backgroundClip: 'content-box'
    },
    '&::-webkit-scrollbar-track': {
      margin: '5px',
      background: 'transparent'
    },
    '::-webkit-scrollbar-corner': {
      background: 'transparent'
    }
  }
}));
