import React from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useStyles } from './styles';

const Footer = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.footerWrapper}>
      <div className={classes.footer}>
        <Typography variant="formMedium" fontWeight={400}>
          {`© Брелок ${dayjs().format('YYYY')}`}
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
