import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditQuery, IQueryPaginationParams, IResponseWithPagination } from 'typings/app';
import { INews, INewsForm } from 'typings/news';
import { customFetchBase } from '../fetchBase';

export const newsAPI = createApi({
  reducerPath: 'newsAPI',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['News'],
  endpoints: (build) => ({
    getNews: build.query<IResponseWithPagination<INews>, IQueryPaginationParams>({
      query: (params) => ({
        url: '/news',
        params
      }),
      providesTags: ['News']
    }),
    getOneNews: build.query<INews, number>({
      query: (id) => ({
        url: `/news/${id}`
      }),
      providesTags: ['News']
    }),
    createNews: build.mutation<null, INewsForm>({
      query: (body) => ({
        url: '/news/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['News']
    }),
    updateNews: build.mutation<null, IEditQuery<INewsForm>>({
      query: (data) => ({
        url: `/news/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['News']
    }),
    deleteNews: build.mutation<null, number>({
      query: (newsId) => ({
        url: `/news/${newsId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['News']
    })
  })
});

export const {
  useGetNewsQuery,
  useGetOneNewsQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation
} = newsAPI;
