import { FormControlLabel, FormControlLabelProps, Radio } from '@mui/material';
import React, { ReactNode } from 'react';

interface IProps extends Omit<FormControlLabelProps, 'label' | 'control'> {
  children: ReactNode;
}

export const RadioInput = ({ children, ...rest }: IProps) => {
  return <FormControlLabel control={<Radio />} label={children} {...rest} />;
};
