import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'primary' | 'secondary' | 'loading' | 'disabled'>()(
  (theme, props, classes) => ({
    button: {
      borderRadius: '12px',
      boxShadow: 'unset !important',
      padding: '11px 22px'
    },
    contained: {
      [`&.${classes.primary}`]: {
        color: theme.palette.common.white,
        background: theme.palette.gradient.main,
        '&:hover': {
          background: theme.palette.gradient.light
        },
        [`&.${classes.disabled}`]: {
          background: `${theme.palette.black['10']} !important`
        }
      },
      [`&.${classes.secondary}`]: {
        color: theme.palette.error.main,
        background: theme.palette.black['3'],
        '&:hover': {
          background: theme.palette.black['10']
        },
        [`& .${classes.loading}`]: {
          color: theme.palette.error.main
        }
      }
    },
    outlined: {
      [`&.${classes.primary}`]: {
        color: theme.palette.primary.main,
        background: 'transparent',
        border: 'unset !important',
        outline: `2px solid ${theme.palette.primary.main}`,
        [`& .${classes.loading}`]: {
          color: theme.palette.primary.main
        },
        [`&.${classes.disabled}`]: {
          outlineColor: `${theme.palette.black['30']} !important`
        }
      }
    },
    small: {
      padding: '6px 24px'
    },
    large: {
      padding: '18px 36px'
    },
    medium: {
      padding: '11px 22px',
      borderRadius: '8px',
      height: '48px',
      [theme.breakpoints.down('desktop')]: {
        padding: '10px 20px',
        fontSize: '14px',
        height: '44px'
      }
    },
    loading: {
      color: theme.palette.common.white
    },
    disabled: {
      color: `${theme.palette.black['30']} !important`
    },
    primary: {},
    secondary: {}
  })
);

export default useStyles;
