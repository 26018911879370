import React from 'react';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MUIModal from '@mui/material/Modal';
import { Button } from 'shared/ui';
import { IModalProps } from './Modal';
import { useStyles } from './Modal.styles';

interface IProps extends Omit<IModalProps, 'children' | 'title'> {
  text: string;
  isLoading: boolean;
}

export const SuccessModalReceipts = ({ text, isOpen, onClose, isLoading }: IProps) => {
  const { classes } = useStyles();
  return (
    <MUIModal open={isOpen} onClose={onClose}>
      <div className={classes.modal}>
        <div className={classes.body}>
          <IconButton
            className={classes.closeIcon}
            onClick={onClose}
            disabled={isLoading}
            disableTouchRipple
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.title}>
            <div className={classes.doneIconWrapper}>
              <DoneIcon className={classes.doneIcon} />
            </div>
            <Typography variant="modalTitle" color="black.90">
              Загрузка завершена
            </Typography>
          </div>
          <div className={classes.contentWrapper}>
            <Typography
              variant="formMedium"
              color="black.40"
              fontWeight={400}
              className={classes.text}
              marginBottom="20px"
            >
              Файл успешно загружен
            </Typography>
            <Typography
              variant="formMedium"
              color="black.90"
              fontWeight={500}
              className={classes.text}
            >
              {text}
            </Typography>
          </div>
        </div>

        <div className={classes.bottom}>
          <Button onClick={onClose} disabled={isLoading}>
            Ок
          </Button>
        </div>
      </div>
    </MUIModal>
  );
};
