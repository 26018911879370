import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsAuth, getUserInfo } from 'store/auth';
import { ROUTES } from 'shared/config/routes';
import { Header } from './header';
import { Footer } from './footer';
import { useStyles } from './AuthLayout.styles';
import { ACCESSES, isAvailableCatalog } from '../access';

export const AuthLayout = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const isAuth = useSelector(getIsAuth);
  const user = useSelector(getUserInfo);

  useEffect(() => {
    if (isAuth && Object.keys(user).length) {
      navigate(
        isAvailableCatalog(user, ACCESSES.request)
          ? ROUTES.REQUESTS.path
          : ROUTES.PERSONAL_CABINET.path
      );
    }
  }, [isAuth, user, navigate]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Header />
        <div className={classes.mainContentWrapper}>
          <div className={classes.mainContent}>
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
