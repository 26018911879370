import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Button, Loading, Pagination } from 'shared/ui';
import { useStyles } from './Table.styles';

interface IProps {
  isLoading?: boolean;
  headerColumns: JSX.Element | JSX.Element[];
  rows: Array<JSX.Element | JSX.Element[]>;
  fullWidth?: boolean;
  grow?: boolean;
  totalPages: number;
  currentPage?: number;
  handlePageChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
  emptyTableText?: string;
  setLastPage: (page: number) => void;
  buttonAction?: () => void;
  buttonTitle?: string;
}

export const Table = ({
  isLoading,
  headerColumns,
  rows,
  fullWidth = true,
  grow = true,
  totalPages,
  currentPage,
  handlePageChange,
  emptyTableText,
  setLastPage,
  buttonAction,
  buttonTitle
}: IProps) => {
  const { cx, classes } = useStyles({ fullWidth: !!fullWidth });

  if (isLoading) {
    return <Loading />;
  }

  //TODO переписать!!!!
  if (!isLoading && Number(currentPage) > totalPages && Number(currentPage) > 1) {
    setLastPage(totalPages);
  }

  if (!isLoading && !rows?.length) {
    return (
      <>
        <div className={classes.wrapper}>
          <MUITable classes={{ root: classes.table }}>
            <MUITableHead classes={{ root: classes.head }}>
              <MUITableRow>{headerColumns}</MUITableRow>
            </MUITableHead>
            <MUITableBody>
              <th
                colSpan={Array.isArray(headerColumns) ? headerColumns.length : 8}
                className={classes.emptyInfo}
              >
                <div>
                  <Typography variant="h3" color="black.90" sx={{ marginBottom: '4px' }}>
                    Данные отсутствуют
                  </Typography>
                  <Typography variant="button" color="black.40">
                    {emptyTableText}
                  </Typography>
                </div>
              </th>
            </MUITableBody>
          </MUITable>
        </div>
        {buttonTitle && (
          <Button
            style={{ marginTop: '32px' }}
            className={classes.actionButton}
            size="medium"
            endIcon={<AddIcon />}
            component="span"
            variant="contained"
            onClick={() => buttonAction?.()}
          >
            {buttonTitle}
          </Button>
        )}
      </>
    );
  }
  return (
    <>
      <div className={cx(classes.wrapper, grow && classes.growWrapper)}>
        <MUITable classes={{ root: classes.table }}>
          <MUITableHead classes={{ root: classes.head }}>{headerColumns}</MUITableHead>
          <MUITableBody>{rows}</MUITableBody>
        </MUITable>
      </div>
      {buttonTitle && (
        <Button
          className={classes.actionButton}
          size="medium"
          endIcon={<AddIcon />}
          component="span"
          variant="contained"
          onClick={() => buttonAction?.()}
        >
          {buttonTitle}
        </Button>
      )}
      {totalPages >= 1 && (
        <div className={classes.paginationWrapper}>
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
        </div>
      )}
    </>
  );
};
