import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import TextField, { BaseTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import { Control, useController } from 'react-hook-form';
import { IOption } from 'typings/app';
import Typography from '@mui/material/Typography';
import { useStyles } from './FilterSearchSelect.styles';
import { Option } from './Option';
import { ListboxComponent } from './ListboxComponent';

interface IProps extends BaseTextFieldProps {
  options: IOption[];
  control: Control<any>;
  label: string;
  name: string;
}

const emptyOptions = [
  {
    key: '0',
    id: 0,
    value: '0',
    label: 'По  вашему  запросу ничего не найдено'
  }
];

export const FilterSearchSelect = ({
  label,
  options,
  control,
  name,
  defaultValue,
  disabled
}: IProps) => {
  const [searchText, setSearchText] = useState('');
  const [showLabel, setShowLabel] = useState<boolean>(false);
  const { classes, cx } = useStyles();
  const {
    field: { value, onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  });

  const searchedOptions = useMemo(() => {
    const filteredOptions = options?.filter((el) => el.label.toLowerCase().includes(searchText.toLowerCase().trim()));

    if (!filteredOptions?.length) {
      return emptyOptions;
    }
    return filteredOptions;
  }, [options, searchText]);

  useEffect(() => {
    const currentOption = options.find((el) => el.value === value);
    if (!currentOption) {
      onChange(null);
      setShowLabel(false);
      setSearchText('');
    } else {
      setShowLabel(true);
      setSearchText(currentOption.label);
    }
  }, [value, options]);

  return (
    <div>
      <Autocomplete
        getOptionLabel={(option) => option?.label ?? options?.find((el) => el.value === option)?.label ?? ''
        }
        isOptionEqualToValue={() => true}
        onChange={(_, newOption) => onChange(newOption ? newOption.value : null)}
        sx={{
          input: cx(classes.input, classes.background, invalid && classes.error),
          label: cx(classes.inputLabel, {
            [classes.inputLabelShrink]: showLabel,
            [classes.disabledLabel]: disabled
          }),
          icon: cx(classes.icon, classes.color, disabled && classes.disabledLabel)
        }}
        classes={{
          root: classes.textFieldRoot,
          paper: cx(
            classes.menuRoot,
            classes.menuPaper,
            classes.background,
            invalid && classes.errorMenuPaper
          )
        }}
        popupIcon={<KeyboardArrowDownIcon />}
        value={value}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            onFocus={() => requestAnimationFrame(() => setShowLabel(true))}
            onBlur={() => {
              setShowLabel(!!(value || defaultValue));
              setSearchText('');
            }}
            classes={{ root: classes.root }}
            InputProps={{ classes: { root: classes.wrapper } }}
            label={label}
            {...params}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
        )}
        ListboxComponent={({ children, onMouseDown, ...params }) => (
          <ListboxComponent
            onMouseDown={onMouseDown}
            {...params}
            key={params.id}
            loading={false}
          >
            {children as ReactElement[]}
          </ListboxComponent>
        )}
        filterOptions={(optionsState) => optionsState}
        options={searchedOptions}
        disabled={disabled}
        renderOption={(props, option) => (
          <Option
            option={option}
            value={value}
            loading={false}
            {...props}
            key={option.id}
          />
        )}
      />
      {error && (
        <Typography variant="formError" component="div" color="error.main">
          {error?.message}
        </Typography>
      )}
    </div>
  );
};
