import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

export const useStyles = makeStyles<{ open: boolean }>()((theme, props, classes) => ({
  wrapper: {
    position: 'fixed',
    left: '21px',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '3%',
    flexShrink: 0,
    width: props.open ? '285px' : '80px',
    height: 'calc(100vh - 30px)',
    maxHeight: 'calc(100vh - 30px)',
    background: theme.palette.gradient.dark,
    color: theme.palette.common.white,
    padding: '32px 0 0',
    borderRadius: '25px',
    transform: 'translate(0, -50%)',
    alignItems: props.open ? 'normal' : 'center',
    transition: 'all 0.5s',
    overflow: 'hidden',
    zIndex: '10',

    [theme.breakpoints.up('desktop')]: {
      height: 'calc(100vh - 104px)',
      maxHeight: 'calc(100vh - 104px)',
      left: '52px'
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 38px'
  },
  titleNotification: {
    fontSize: '40px',
    borderRadius: '100%',
    color: theme.palette.common.white,
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    padding: '8px',
    cursor: 'pointer'
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '0 38px'
  },
  profileAvatar: {
    width: '50px',
    height: '50px',
    borderRadius: '100px',
    overflow: 'hidden'
  },
  profileName: {
    flexGrow: 1,
    overflow: 'hidden',
    '& >div': {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  arrowWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '16px 38px',
    cursor: 'pointer',
    transition: 'all 0.5s',
    '&:hover': {
      background: alpha(theme.palette.common.white, 0.1)
    }
  },
  arrowIcon: {
    display: 'flex',
    height: '24px',
    width: '24px',
    padding: '4px 0 4px 6px'
  }
}));
