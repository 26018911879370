import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStore } from '../store';

interface IInitialState {
  isInit: boolean;
}

const initialState: IInitialState = {
  isInit: false
};
export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setIsInit: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isInit = action.payload;
    }
  }
});

export const getIsInit = (state: RootStore) => state.app.isInit;

export const { setIsInit } = appSlice.actions;
