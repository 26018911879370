import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getIsAuth,
  getUserInfo,
  useLogoutMutation,
  useSendFirebaseTokenMutation
} from 'store/auth';
import { LogoutModal, Sidebar } from 'shared/ui';
import { JWTToken } from 'shared/utils';
import { toastErrorHandler } from 'shared/notifications';
import { ROUTE } from 'shared/config';
import { SideBar } from 'shared/utils/sideBar';
import { useFirebaseNotifications } from 'shared/hooks';
import { useStyles } from './SidebarLayout.styles';

export const SidebarLayout = () => {
  const [isOpenSideBar, setIsOpenSideBar] = useState<boolean>(SideBar.getIsOpen());
  const { classes } = useStyles({ isOpenSideBar });
  const navigate = useNavigate();

  const isAuth = useSelector(getIsAuth);
  const user = useSelector(getUserInfo);
  const { token } = useFirebaseNotifications(isAuth);

  const [confirmLogout, setConfirmLogout] = useState<boolean>(false);

  const [logout] = useLogoutMutation();
  const [sendToken] = useSendFirebaseTokenMutation();

  useEffect(() => {
    if (token) {
      sendToken(token);
    }
  }, [token]);

  useEffect(() => {
    if (!isAuth) {
      navigate(ROUTE.SIGN_IN);
    }
  }, [isAuth]);

  useLayoutEffect(() => {
    setIsOpenSideBar(SideBar.getIsOpen());
  }, []);

  useEffect(() => {
    SideBar.setIsOpen(isOpenSideBar);
  }, [isOpenSideBar]);

  const logoutHandler = () => {
    setConfirmLogout(true);
  };

  const confirmLogoutHandler = async () => {
    try {
      await logout().unwrap();
      JWTToken.removeTokens();
      navigate(ROUTE.SIGN_IN);
    } catch (err) {
      toastErrorHandler(err);
    }
  };

  return (
    <div className={classes.wrapper}>
      <LogoutModal
        onClose={() => setConfirmLogout(false)}
        isLoading={false}
        isOpen={confirmLogout}
        handleSubmit={confirmLogoutHandler}
      />
      <Sidebar
        logoutHandler={logoutHandler}
        user={user}
        setIsOpenSideBar={setIsOpenSideBar}
        open={isOpenSideBar}
      />
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  );
};
