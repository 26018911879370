import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import { Control, useController } from 'react-hook-form';
import { IOption } from 'typings/app';
import Typography from '@mui/material/Typography';
import { useStyles } from './multiSearchSelect.styles';
import { Option } from '../searchSelect/Option';
import { ListboxComponent } from '../searchSelect/ListboxComponent';

interface IProps extends BaseTextFieldProps {
    options: IOption[];
    control: Control<any>;
    label: string;
    name: string;
    selectAll?: string;
}

export const MultiSearchSelect = ({
                                      label,
                                      options,
                                      control,
                                      name,
                                      defaultValue,
                                      disabled,
                                      selectAll
                                  }: IProps) => {
    const [showLabel, setShowLabel] = useState<boolean>(false);
    const { classes, cx } = useStyles();
    const selectAllOption: IOption = {
        id: 0,
        label: selectAll ?? '',
        value: 'all selected options'
    }
    const {
        field: { value, onChange },
        fieldState: { invalid, error }
    } = useController({
        name,
        control,
        defaultValue
    });
    const onChangeHandler = (event: SyntheticEvent<Element, Event>, newValues: IOption[]) => {
        if (newValues.find((el) => el?.value === selectAllOption.value)) {
            return onChange(options.map((el) => el.value))
        }
        onChange(newValues.map((el) => el?.value || el).filter((el) => el))
    }

    useEffect(() => {
        setShowLabel(!!value?.length)
    }, [value]);

    // этот эффект для того что бы очищать value если изменились options
    useEffect(() => {
        if (!options?.filter((el) => value?.includes(el.value)).length) {
           onChange([])
        }
    }, [options]);

    return (
        <div>
            <Autocomplete
                disableClearable
                filterOptions={(options, state) => options.filter((el) => el.label?.trim().toLowerCase().includes(state.inputValue?.trim().toLowerCase()))}
                getOptionLabel={(option) => options.find((elem) => elem.value === option)?.label ?? ''}
                isOptionEqualToValue={(option, value) => option.value === value}
                multiple
                size="small"
                options={selectAll ? [...options, selectAllOption] : options}
                value={value || []}
                sx={{
                    input: cx(classes.input),
                    label: cx(classes.inputLabel, {
                        [classes.inputLabelShrink]: showLabel,
                        [classes.disabledLabel]: disabled
                    }),
                    icon: cx(classes.color, disabled && classes.disabledLabel)
                }}
                classes={{
                    inputRoot: cx(classes.inputRoot, invalid && classes.error),
                    root: classes.textFieldRoot,
                    endAdornment: classes.endAdornment,
                    paper: cx(
                        classes.menuRoot,
                        classes.menuPaper,
                        classes.background,
                        invalid && classes.errorMenuPaper
                    )
                }}
                popupIcon={<KeyboardArrowDownIcon/>}
                onChange={onChangeHandler}
                renderInput={(params) => (
                    <TextField
                        onFocus={() => requestAnimationFrame(() => setShowLabel(true))}
                        onBlur={() => setShowLabel(!!value?.length)}
                        label={label}
                        {...params}
                    />
                )}
                ListboxComponent={({ children, ...params }) => (
                    <ListboxComponent
                        {...params}
                        key={params.id}
                        loading={false}
                    >
                        {children as ReactElement[]}
                    </ListboxComponent>
                )}
                renderOption={(props, option) => (
                    <Option
                        option={option}
                        value={value}
                        loading={false}
                        {...props}
                        key={option.id}
                    />
                )}
                noOptionsText={'По  вашему  запросу ничего не найдено'}
                disabled={disabled}
            />
            {error && (
                <Typography variant="formError" component="div" color="error.main">
                    {error?.message}
                </Typography>
            )}
        </div>
    );
};
