import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDPdZWvwOmw7bEguOFNVXL7C9wCKN1wAqI',
  authDomain: 'zapstroy-f2880.firebaseapp.com',
  projectId: 'zapstroy-f2880',
  storageBucket: 'zapstroy-f2880.appspot.com',
  messagingSenderId: '276361406845',
  appId: '1:276361406845:web:83df817c02f0883fcf0414',
  vapidKey:
    'BKhy-xq7mDIBCKIfrAEmMz-aU2oGkzXCHO8tJaRdDffN2b6N-L00GFjDHmlyQg9nx6iwt1IUB1NdvPzDextEeHE'
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const getOrRegisterServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const serviceWorker = await window.navigator.serviceWorker.getRegistration(
      '/firebase-push-notification-scope'
    );
    if (serviceWorker) {
      await serviceWorker.update();
      return serviceWorker;
    }
    const newSW = await window.navigator.serviceWorker.register(
      '/firebase-messaging-sw.js',
      {
        scope: '/firebase-push-notification-scope'
      }
    );
    // wait for an update to return the finished SW
    await newSW.update();
    return newSW;
  }
  throw new Error('The browser doesn`t support service worker.');
};
export const getFirebaseToken = () => getOrRegisterServiceWorker().then((serviceWorkerRegistration) => getToken(messaging, {
      vapidKey: firebaseConfig.vapidKey,
      serviceWorkerRegistration
    }));

export const onDeleteToken = () => deleteToken(messaging)
    .catch((err) => console.log(err))
    .finally(async () => {
      if ('serviceWorker' in navigator) {
        const serviceWorker = await window.navigator.serviceWorker.getRegistration(
          '/firebase-push-notification-scope'
        );
        if (serviceWorker) {
          await serviceWorker.unregister();
        }
      }
    });

export const onFirebaseMessage = () => new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
