import React from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './Loading.styles';

export const Loading = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.loadingContainer}>
      <Typography variant="h1" fontWeight={500} color="black.90">
        Пожалуйста, подождите
      </Typography>
      <Typography variant="button" color="black.40">
        Идет загрузка данных...
      </Typography>
      <CircularProgress size={60} sx={{ marginTop: '24px' }} />
    </div>
  );
};
