import React from 'react';
import { App, bootstrap } from 'app';
import { setupStore } from 'store/store';
import { Provider } from 'react-redux';

const main = async () => {
  bootstrap({
    rootElement: (
      <React.StrictMode>
        <Provider store={setupStore()}>
          <React.Suspense>
            <App />
          </React.Suspense>
        </Provider>
      </React.StrictMode>
    ),
    selector: '#root'
  });
};
void main();
