import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ isOpenSideBar: boolean }>()(
  (theme, props, classes) => ({
    wrapper: {
      padding: props.isOpenSideBar ? '15px 0 15px 305px' : '15px 0 15px 100px', //sidebar width 285 + 48 left padding
      [theme.breakpoints.up('desktop')]: {
        padding: props.isOpenSideBar ? '52px 0 52px 335px' : '52px 0 52px 130px'
      },
      transition: 'all 0.5s'
    },
    content: {
      margin: '0 42px',
      height: 'calc(100vh - 30px)',

      [theme.breakpoints.up('desktop')]: {
        height: 'calc(100vh - 104px)'
      }
    }
  })
);
