import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ value?: boolean }, 'primary' | 'secondary'>()(
  (theme, props, classes) => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center'
    },
    inputsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '23px'
    },
    root: {
      width: '64px',
      height: '64px',
      borderRadius: '12px',
      fontSize: '16px',
      lineHeight: '28px',
      caretColor: 'transparent',
      '& input': {
        textAlign: 'center',
        paddingTop: '18px',
        paddingBottom: '18px',
        height: '28px',
        '&::placeholder': {
          fontSize: '20px',
          lineHeight: '25px',
          fontWeight: 500
        }
      },
      [`&.${classes.primary}`]: {
        backgroundColor: props.value ? 'transparent' : `${theme.palette.black['3']}`,
        boxShadow: props.value ? `0px 8px 20px ${theme.palette.black['10']}` : 'none',
        color: theme.palette.black['90']
      }
    },
    rootFocused: {
      [`&.${classes.primary}`]: {
        backgroundColor: 'transparent',
        boxShadow: `0px 8px 20px ${theme.palette.black['10']}`
      }
    },
    error: {
      backgroundColor: `${theme.palette.error.light} !important`,
      outline: `1px solid ${theme.palette.error.main} !important`
    },
    primary: {},
    secondary: {}
  })
);
