import React from 'react';
import { useStyles } from './TableRowItemsWrap.styles';

interface IProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const TableRowItemsWrap = ({ children, onClick }: IProps) => {
  const { classes } = useStyles();
  return (
    <div onClick={onClick} className={classes.bodyCell}>
      {children}
    </div>
  );
};
