import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, props, classes) => ({
  wrapper: {
    display: 'flex',
    background: '#FFFAEA',
    gap: '8px',
    padding: '0 16px',
    borderRadius: '12px',
    maxWidth: '100%'
  },
  iconWrapper: {
    padding: '14px 0'
  },
  icon: {
    width: '20px',
    height: '20px',
    color: theme.palette.warning.main
  },
  content: {
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column'
  }
}));
