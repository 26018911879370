import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1
  }
}));
