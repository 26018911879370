import React from 'react';
import { useStyles } from './IconButton.styles';

interface IProps {
  Icon: React.ElementType;
  onClick?: (event: any) => void;
  disabled?: boolean;
  className?: string;
  id?: string;
}

export const IconButton = ({
  Icon,
  onClick,
  disabled,
  className,
  id,
  ...props
}: IProps) => {
  const { classes, cx } = useStyles();
  return (
    <button
      onClick={onClick}
      className={cx(classes.button, disabled && classes.disabled, className)}
      disabled={disabled}
      id={id}
      {...props}
    >
      <Icon />
    </button>
  );
};
