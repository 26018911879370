import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, props, classes) => ({
  wrapper: {},
  root: {
    borderRadius: '12px',
    fontSize: '16px',
    lineHeight: '28px',
    paddingLeft: '10px',
    paddingRight: '24px',
    border: 'none',
    backgroundColor: `${theme.palette.black['3']}`,
    color: `${theme.palette.black['60']}`,
    '& input': {
      paddingTop: '10px',
      paddingBottom: '10px',
      height: '28px',
      border: 'none'
    },
    '& fieldset': {
      border: 'none'
    }
  },
  inputLabel: {
    display: 'none !important',
    left: '12px',
    fontSize: '16px',
    lineHeight: '28px',
    color: `${theme.palette.black['60']} !important`,
    transform: 'translate(12px, 11px) scale(1)',
    border: 'none'
  },
  inputLabelShrink: {
    transform: 'translate(12px, 6px) scale(0.75)'
  },
  error: {
    backgroundColor: `${theme.palette.error.light} !important`,
    outline: `1px solid ${theme.palette.error.main} !important`
  },
  adornment: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto !important',
    height: 'auto',
    color: theme.palette.black['60']
  },
  primary: {},
  secondary: {}
}));
