import React from 'react';
import MUITabs, { TabsProps } from '@mui/material/Tabs';
import MUITab, { TabProps } from '@mui/material/Tab';
import { useStyles } from './Tabs.styles';

interface IProps extends TabsProps {
  tabs: TabProps[];
  name: string;
  defaultValue?: string;
  className?: string;
}

export const Tabs = ({ tabs, value, name, onChange, className }: IProps) => {
  const { cx, classes } = useStyles();

  return (
    <MUITabs
      name={name}
      value={value}
      onChange={onChange}
      className={cx(classes.tabs, className)}
    >
      {tabs?.map((tab, index) => (
        <MUITab
          key={index}
          value={tab.value}
          label={tab.label}
          className={cx(classes.tab, value === tab.value && classes.activeTab)}
        />
      ))}
    </MUITabs>
  );
};
