import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '24px'
  },
  iconButton: {
    position: 'static'
  },
  icon: {
    color: theme.palette.black['40']
  }
}));
