import React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useStyles } from './MenuDropDown.styles';
import { Button } from '../../form/button/Button';

export interface IMenuProps extends MenuProps {
  isOpen: boolean;
  onClose: () => void;
  onClear: () => void;
  children: React.ReactNode;
  title?: string;
  handleSubmit: any;
  isLoading?: boolean;
  error?: any;
}

export const MenuDropDown = ({
  isOpen,
  onClose,
  onClear,
  children,
  title,
  handleSubmit,
  isLoading,
  ...rest
}: IMenuProps) => {
  const { classes, cx } = useStyles();

  return (
    <Menu
      className={cx(classes.menu, rest.className)}
      onClose={onClose}
      elevation={rest.elevation ?? 0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      {...rest}
    >
      <div className={classes.modal}>
        <div className={classes.body}>
          <IconButton className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          {title && (
            <div className={classes.title}>
              <Typography variant="modalTitle" color="black.90">
                {title}
              </Typography>
            </div>
          )}
          {children}
        </div>
        <div className={classes.bottom}>
          <Button color="secondary" onClick={onClear} disabled={isLoading}>
            Очистить
          </Button>
          <Button disabled={isLoading} loading={isLoading} onClick={handleSubmit}>
            Применить
          </Button>
        </div>
      </div>
    </Menu>
  );
};
