import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { appSlice } from 'store/app';
import { authAPI, authSlice } from 'store/auth';
import { rolesAPI } from 'store/roles';
import { complexAPI } from './complexes';
import { employeesAPI } from './employees';
import { housesAPI } from './houses';
import { apartsAPI } from './aparts';
import { clientAPI } from './clients';
import { clientApartsAPI } from './clientAparts';
import { requestApi } from './request';
import { newsAPI } from './news';
import { fileApi } from './file';
import { quizApi } from './quiz';
import { meterApi } from './meter';
import { billApi } from './bill';
import { receiptApi } from './receipt';
import { camerasAPI } from './cameras';
import { faqApi } from './faq';
import { contactsApi } from './contacts';

const rootReducer = combineReducers({
  [rolesAPI.reducerPath]: rolesAPI.reducer,
  [employeesAPI.reducerPath]: employeesAPI.reducer,
  app: appSlice.reducer,
  auth: authSlice.reducer,
  [rolesAPI.reducerPath]: rolesAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [complexAPI.reducerPath]: complexAPI.reducer,
  [housesAPI.reducerPath]: housesAPI.reducer,
  [apartsAPI.reducerPath]: apartsAPI.reducer,
  [clientAPI.reducerPath]: clientAPI.reducer,
  [clientApartsAPI.reducerPath]: clientApartsAPI.reducer,
  [requestApi.reducerPath]: requestApi.reducer,
  [newsAPI.reducerPath]: newsAPI.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [quizApi.reducerPath]: quizApi.reducer,
  [meterApi.reducerPath]: meterApi.reducer,
  [billApi.reducerPath]: billApi.reducer,
  [receiptApi.reducerPath]: receiptApi.reducer,
  [camerasAPI.reducerPath]: camerasAPI.reducer,
  [faqApi.reducerPath]: faqApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer
});
export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(
        rolesAPI.middleware,
        authAPI.middleware,
        employeesAPI.middleware,
        complexAPI.middleware,
        housesAPI.middleware,
        apartsAPI.middleware,
        clientAPI.middleware,
        clientApartsAPI.middleware,
        requestApi.middleware,
        newsAPI.middleware,
        fileApi.middleware,
        quizApi.middleware,
        meterApi.middleware,
        billApi.middleware,
        receiptApi.middleware,
        camerasAPI.middleware,
        faqApi.middleware,
        contactsApi.middleware
      );
    }
  });
};

export type RootStore = ReturnType<typeof rootReducer>;
