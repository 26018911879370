import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getUserInfo } from 'store/auth';
import { ROUTES } from 'shared/config/routes';
import { ACCESSES, isAvailableCatalog } from '../access';

export const RootLayout = () => {
  const { pathname } = useLocation();
  const user = useSelector(getUserInfo);

  if (isAvailableCatalog(user, ACCESSES.request)) {
    return <Navigate to={ROUTES.REQUESTS.path} replace />;
  }

  if (pathname === ROUTES.MAIN.path) {
    return <Navigate to={ROUTES.PERSONAL_CABINET.path} replace />;
  }

  return <Navigate to={ROUTES.NOT_FOUND_PAGE.path} replace />;
};
