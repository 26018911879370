import React, { useState } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IInputProps, Input } from './Input';

export const PasswordInput = (props: Omit<IInputProps, 'endIcon'>) => {
  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const handleIconClick = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <Input
      {...props}
      type={isShowPassword ? 'text' : 'password'}
      endIcon={
        isShowPassword ? (
          <VisibilityOffIcon onClick={handleIconClick} style={{ cursor: 'pointer' }} />
        ) : (
          <VisibilityIcon onClick={handleIconClick} style={{ cursor: 'pointer' }} />
        )
      }
    />
  );
};
