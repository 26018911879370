import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, props, classes) => ({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px'
  }
}));
