import React, { useEffect, useRef, useState } from 'react';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import { useStyles } from './CodeInput.styles';

export interface IInputProps extends BaseTextFieldProps {
  invalid: boolean;
  index: number;
  currentIndex: number;
  onChangeFocus: (index: number) => void;
  onChangeValue: (index: number, value: number | null) => void;
}

export const CodeTextField = ({
  invalid,
  onChangeFocus,
  index,
  onChangeValue,
  currentIndex,
  disabled
}: IInputProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [value, setValue] = useState<undefined | number>(undefined);
  const { classes, cx } = useStyles({
    value: !!value?.toString()
  });
  const ref = useRef<HTMLInputElement>();

  const onFocus = () => {
    onChangeFocus(index);
    setFocused(true);
  };
  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0) setFocused(false);
  };

  const onRemoveValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      setValue(undefined);
      onChangeValue(currentIndex, null);
      onChangeFocus(currentIndex - 1);
    } else if (/^[0-9]*$/.test(e.key)) {
      setValue(Number(e.key));
      onChangeValue(currentIndex, Number(e.key));
    }
  };

  useEffect(() => {
    if (currentIndex === index) {
      ref?.current?.focus();
      setFocused(true);
    } else {
      setFocused(false);
    }
  }, [currentIndex, index]);

  return (
    <div>
      <TextField
        disabled={disabled}
        inputRef={ref}
        value={value ?? ''}
        onChange={() => {}}
        placeholder={focused ? '' : '-'}
        onFocus={onFocus}
        onBlur={onBlur}
        variant={'filled'}
        autoComplete="off"
        type="text"
        InputLabelProps={{
          classes: {
            root: cx(classes.primary)
          },
          shrink: !!value || focused
        }}
        InputProps={{
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => onRemoveValue(e),
          disableUnderline: true,
          classes: {
            root: cx(classes.root, classes.primary, invalid && classes.error),
            focused: cx(classes.rootFocused, classes.primary)
          }
        }}
      />
    </div>
  );
};
