import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IUser } from 'typings/auth';
import { ReactComponent as Logo } from 'shared/assets/images/logo.svg';
import { ReactComponent as SmallLogo } from 'shared/assets/images/smollLogo.svg';
import { ReactComponent as Avatar } from 'shared/assets/images/avatar.svg';
import { LightTooltip } from 'shared/ui/index';
import { Navigation } from './navigation/Navigation';
import { useStyles } from './Sidebar.styles';
import { SmallNavigation } from './smallNavigation/Navigation';

interface IProps {
  user: IUser;
  logoutHandler: () => void;
  setIsOpenSideBar: (open: boolean) => void;
  open: boolean;
}

export const Sidebar = ({ user, logoutHandler, open, setIsOpenSideBar }: IProps) => {
  const { classes } = useStyles({ open });

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        {open && <Logo />}
        {!open && <SmallLogo />}
      </div>
      <div className={classes.profile}>
        {!user?.avatar && <Avatar className={classes.profileAvatar} />}
        {user?.avatar && (
          <img src={user?.avatar} className={classes.profileAvatar} alt="avatar" />
        )}
        {open && (
          <LightTooltip
            title={
              <>
                {user?.firstName} {user?.lastName}
              </>
            }
          >
            <div className={classes.profileName}>
              <div>{user?.firstName}</div>
              <div>{user?.lastName}</div>
            </div>
          </LightTooltip>
        )}
      </div>
      {open && <Navigation logoutHandler={logoutHandler} />}
      {!open && <SmallNavigation logoutHandler={logoutHandler} open={open} />}
      <div className={classes.arrowWrapper} onClick={() => setIsOpenSideBar(!open)}>
        {open && <ArrowBackIosIcon className={classes.arrowIcon} />}
        {!open && <ArrowForwardIosIcon className={classes.arrowIcon} />}
      </div>
    </div>
  );
};
