import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditQuery, IQueryPaginationParams, IResponseWithPagination } from 'typings/app';
import { IFaq, IFaqForm } from 'typings/faq';
import { customFetchBase } from '../fetchBase';

export const faqApi = createApi({
  reducerPath: 'faqApi',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['FAQ'],
  endpoints: (build) => ({
    getFaq: build.query<IResponseWithPagination<IFaq>, IQueryPaginationParams>({
      query: (params) => ({
        url: '/faq',
        params
      }),
      providesTags: ['FAQ']
    }),
    getOneFaq: build.query<IFaq, number>({
      query: (id) => ({
        url: `/faq/${id}`
      }),
      providesTags: ['FAQ']
    }),
    createFaq: build.mutation<null, IFaqForm>({
      query: (body) => ({
        url: '/faq/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['FAQ']
    }),
    updateFaq: build.mutation<null, IEditQuery<IFaqForm>>({
      query: (data) => ({
        url: `/faq/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['FAQ']
    }),
    deleteFaq: build.mutation<null, number>({
      query: (id) => ({
        url: `/faq/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['FAQ']
    })
  })
});

export const {
  useCreateFaqMutation,
  useDeleteFaqMutation,
  useUpdateFaqMutation,
  useGetFaqQuery,
  useGetOneFaqQuery
} = faqApi;
