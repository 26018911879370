import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from '../fetchBase';
import { IFile } from '../../typings/file';

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: customFetchBase,
  tagTypes: ['File'],
  endpoints: (build) => ({
    postFile: build.mutation<IFile, FormData>({
      query: (body) => ({
        url: 'file',
        method: 'POST',
        body,
        formData: true
      }),
      invalidatesTags: ['File']
    }),
    downloadFile: build.query<any, string>({
      query: (url) => ({
        url,
        responseHandler: async (response) => {
          const url = window.URL.createObjectURL(await response.blob());
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', '');

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link?.parentNode?.removeChild(link);
        },
        cache: 'no-cache'
      })
    })
  })
});

export const { usePostFileMutation, useLazyDownloadFileQuery } = fileApi;
