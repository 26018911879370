import { useEffect, useState } from 'react';

import { toastNotification } from 'shared/notifications';
import { INotification } from 'typings/app';
import { getFirebaseToken, onDeleteToken } from './config';
import { NOTIFICATION_DATA } from './model';

export const useFirebaseNotifications = (isAuth: boolean) => {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const getTokenHandler = async () => {
    try {
      setLoading(true);
      const res = await getFirebaseToken();
      setToken(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const showFireBaseModal = (event: MessageEvent<INotification>) => {
    const { type, parentId } = event.data.data;
    const title = NOTIFICATION_DATA[type].title.replace('<number>', `${parentId}`);
    const description = NOTIFICATION_DATA[type].description.replace(
      '<number>',
      `${parentId}`
    );
    const url = NOTIFICATION_DATA[type].redirect.replace('<number>', `${parentId}`);
    toastNotification(title, description, () => window.location.replace(url));

    const audio = new Audio('notification.mp3');
    audio.play()
  };

  useEffect(() => {
    if (isAuth) {
      getTokenHandler().then(() => navigator.serviceWorker.addEventListener('message', showFireBaseModal));
    } else if (token) {
      onDeleteToken();
    }
    return () => {
      navigator.serviceWorker.removeEventListener('message', showFireBaseModal);
    };
  }, [isAuth]);
  return {
    token,
    loading
  };
};
