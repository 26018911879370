import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, props, classes) => ({
  accordionWrapper: {
    background: 'transparent',
    color: theme.palette.common.white,
    boxShadow: 'unset',
    margin: '0px!important',
    position: 'initial',
    '&::before': {
      display: 'none'
    }
  },
  accordionIcon: {
    color: theme.palette.common.white
  },
  accordionItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    margin: 'unset !important',
    whiteSpace: 'nowrap',
    cursor: 'pointer'
  },
  accordionDetails: {
    padding: 'unset !important'
  },
  accordionSubItem: {
    padding: '13px 0 13px 73px',
    cursor: 'pointer'
  },
  accordionExpanded: {
    minHeight: 'unset'
  },
  hovered: {
    '&:hover': {
      background: alpha(theme.palette.common.white, 0.1)
    },
    '&[aria-expanded="true"]': {
      minHeight: 'unset'
    }
  },
  padding: {
    padding: '13px 38px'
  },
  active: {
    position: 'relative',
    background: alpha(theme.palette.common.white, 0.1),
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '-1.5px',
      bottom: '-1.5px',
      width: '5px',
      background: theme.palette.common.white,
      borderRadius: '5px 0 0 5px'
    }
  },
  dark: {
    color: theme.palette.black['30'],
    '&:hover': {
      color: theme.palette.common.white
    }
  },
  hidden: {
    display: 'none'
  },
  title: {
    whiteSpace: 'nowrap'
  }
}));
