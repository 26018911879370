import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, props) => ({
  bodyRow: {
    '&:nth-of-type(2n)': {
      background: theme.palette.black['3']
    }
  },
  actionRow: {
    cursor: 'pointer'
  }
}));
