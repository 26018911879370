import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useStyles } from './ClueTooltip.styles';

interface IProps extends Omit<TooltipProps, 'children'> {
  disablePortal?: boolean;
}

export const ClueTooltip = (props: IProps) => {
  const { classes } = useStyles();
  const { title, placement, disablePortal = true } = props;

  const typographyTitle = (
    <Typography variant="button" fontWeight="400" whiteSpace="break-spaces">
      {title}
    </Typography>
  );

  return (
    <Tooltip
      {...props}
      classes={{ ...classes }}
      title={typographyTitle}
      PopperProps={{
        disablePortal,
        popperOptions: {
          placement: placement ?? 'bottom-start'
        }
      }}
    >
      <HelpOutlineOutlinedIcon className={classes.icon} />
    </Tooltip>
  );
};
