import { ReactNode } from 'react';

export interface IQueryParams {
  id: number | string;
}

export interface IQueryPaginationParams {
  id?: number;
  type?: string;
  query?: string;
  search?: string;
  page: number | string;
  limit: number | string;
}

export interface IEditQuery<Body> {
  id: number | string;
  body: Body;
}

export interface IResponseWithPagination<D> {
  data: D[];
  totalPages: number;
  totalCount: number;
}

export interface IPaginationResponse {
  totalPages: number;
  totalCount: number;
}

export interface IOption {
  id: number;
  value: number | string | boolean;
  label: string;
  name?: string;
  prefix?: ReactNode;
  optionId?: number;
}

export interface IMessageResponse {
  message: string;
}

export enum NOTIFICATION_TYPE {
  REQUEST_NEW = 'REQUEST_NEW',
  REQUEST_COMMENT = 'REQUEST_COMMENT',
  REQUEST_DONE = 'REQUEST_DONE',
  REQUEST_RETURN = 'REQUEST_RETURN',
  REQUEST_RATING = 'REQUEST_RATING'
}

export interface INotification {
  data: {
    apartId: number;
    clientId?: number;
    parentId: number;
    user_id: number;
    type: NOTIFICATION_TYPE;
  };
}
