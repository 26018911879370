import React from 'react';
import { Control, useController } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as DatePickerMUI } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import 'dayjs/locale/ru';
import Typography from '@mui/material/Typography';
import { useStyles } from './DatePicker.styles';

interface IProps {
  control: Control<any>;
  label?: string;
  name: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  disablePast?: boolean;
  color?: 'error' | 'primary' | 'secondary' | 'wrapper' | 'root';
}

const weekDay: Record<string, string> = {
  Mo: 'пн',
  Tu: 'вт',
  We: 'ср',
  Th: 'чт',
  Fr: 'пт',
  Sa: 'сб',
  Su: 'вс'
};

export const DatePicker = ({
  label,
  control,
  name,
  defaultValue,
  disabled,
  color = 'primary',
  disablePast = true,
  className
}: IProps) => {
  const { classes, cx } = useStyles();
  const {
    field: { value, onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  });

  const dayFormatter = (day: string) => {
    return weekDay[day];
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
      <DatePickerMUI
        className={className}
        components={{
          RightArrowButton: 'webview',
          LeftArrowButton: 'webview',
          SwitchViewIcon: 'base'
        }}
        dayOfWeekFormatter={dayFormatter}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        inputFormat={'DD/MM/YYYY'}
        disablePast={disablePast}
        renderInput={({ InputLabelProps, InputProps, ...params }) => (
          <div className={params.className}>
            {/*@ts-ignore*/}
            <TextField
              id={params.id}
              classes={{ root: classes.wrapper }}
              InputLabelProps={{
                ...InputLabelProps,
                classes: {
                  root: cx(classes.inputLabel),
                  shrink: classes.inputLabelShrink
                }
              }}
              InputProps={{
                ...InputProps,
                disableUnderline: true,
                classes: {
                  root: cx(classes.root, classes[color], invalid && classes.error)
                }
              }}
              {...params}
            />
            {error && (
              <Typography variant="formError" component="div" color="error.main">
                {error?.message}
              </Typography>
            )}
          </div>
        )}
      />
    </LocalizationProvider>
  );
};
