import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#226A6A',
      light: '#1B7065'
    },
    gradient: {
      main: 'linear-gradient(270deg, #28464E 0%, #335E65 150.62%);',
      light: 'linear-gradient(270deg, #375E68 0%, #4F8B95 150.62%)',
      dark: 'linear-gradient(0deg, #184450 13.22%, #156E7C 105.67%)'
    },
    warning: {
      main: '#EB8154',
      light: '#FFFAEA'
    },
    error: {
      main: '#AD1F00',
      light: '#FFEAEA'
    },
    done: {
      light1: '#EAFFF2'
    },
    common: {
      white: '#FFFFFF',
      black: '#231F20'
    },
    black: {
      100: '#231F20',
      90: '#343531',
      80: '#4A4B48',
      70: '#61625F',
      60: '#777876',
      50: '#8E8E8C',
      40: '#A5A5A3',
      30: '#BBBBBA',
      20: '#D2D2D1',
      10: '#E8E8E8',
      5: '#F4F4F4',
      3: '#F8F8F8'
    },
    status: {
      primary: '#28464e',

      // status resident
      greenLight: '#DAE8E9',
      blueLight1: '#D4E4F3',

      // status request
      blueLight: '#B8C3FF',
      blueLight2: '#C6B3FD',
      greenLight2: '#8BBC90',
      roseLight: '#F797B4',
      orangeLight: '#FEC48F',
      redLight: '#FFA9A9',

      // status kanban board
      blueLight3: '#B7CFE4',
      blueLight4: '#DEDBF2',
      roseLight1: '#F0DBF2',
      roseLight2: '#F2DBDB',
      orangeLight1: '#FFE6B7',
      greenLight1: '#DBF2DD'
    },
    kanban: {
      activeCard: '#EEF6F8',
      activeCol: '#E9F3F3'
    },
    metersType: {
      purpure1: '#E2D4F3',
      beige1: '#FFEEDF'
    }
  },
  breakpoints: {
    values: {
      laptop: 1024,
      desktop: 1280
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'Pragmatica !important',
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 500
    },
    modalTitle: {
      fontSize: '30px',
      lineHeight: '38px'
    },
    h1: {
      fontSize: '30px',
      lineHeight: '42px'
    },
    h2: {
      fontSize: '26px',
      lineHeight: '30px'
    },
    h3: {
      fontSize: '20px',
      lineHeight: '25px'
    },
    subtitle1: {
      fontSize: '18px',
      lineHeight: '22px'
    },
    subtitle2: {
      fontSize: '15px',
      lineHeight: '20px'
    },
    button: {
      fontSize: '16px',
      lineHeight: '28px'
    },
    smallButton: {
      fontSize: '14px',
      lineHeight: '28px'
    },
    formMedium: {
      fontSize: '16px',
      lineHeight: '20px'
    },
    other: {
      fontSize: '12px',
      lineHeight: '15px'
    },
    formError: {
      fontSize: '13px',
      lineHeight: '17px',
      fontWeight: 400,
      marginTop: '5px'
    },
    tabBar: {
      fontSize: '11px',
      lineHeight: '14px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        a: {
          display: 'block',
          color: theme.palette.common.white,
          textDecoration: 'none'
        }
      })
    }
  }
});
