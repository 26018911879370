import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    gap: 10
  },
  icon: {
    width: '38px',
    minWidth: '38px',
    height: '38px'
  },
  desc: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
