import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IQueryPaginationParams,
  IQueryParams,
  IResponseWithPagination
} from 'typings/app';
import {
  IApart,
  IApartForm,
  IClientApart,
  IClientApartHistory,
  IClientApartInfo,
  IClientForm,
  IClientUpdateForm,
  IResident
} from 'typings/clientApart';
import { customFetchBase } from '../fetchBase';

export const clientApartsAPI = createApi({
  reducerPath: 'clientApartsAPI',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['ClientAparts'],
  endpoints: (build) => ({
    getClientAparts: build.query<
      IResponseWithPagination<IClientApart[]>,
      IQueryPaginationParams
    >({
      query: () => ({
        url: '/apart-client'
      }),
      providesTags: ['ClientAparts']
    }),
    getClientApart: build.query<IClientApartInfo, IQueryParams>({
      query: ({ id }) => ({
        url: `/apart-client/${id}`
      }),
      providesTags: ['ClientAparts']
    }),
    getClientApartHistory: build.query<
      IResponseWithPagination<IClientApartHistory>,
      IQueryPaginationParams
    >({
      query: ({ id, limit, page }) => ({
        url: `/apart-client/history/${String(id)}`,
        params: { page, limit }
      }),
      providesTags: ['ClientAparts']
    }),
    getClientApartResidents: build.query<
      IResponseWithPagination<IResident>,
      IQueryPaginationParams
    >({
      query: ({ id, limit, page }) => ({
        url: `/apart-client/tenants/${String(id)}`,
        params: { page, limit }
      }),
      providesTags: ['ClientAparts']
    }),
    getClientApartsByClientId: build.query<IClientApart[], IClientForm>({
      query: (data) => ({
        url: `/apart-client/client/${String(data.clientId)}`
      }),
      providesTags: ['ClientAparts']
    }),
    getClientApartByApartId: build.query<IApart, IApartForm>({
      query: (data) => ({
        url: `/apart-client/apart/${String(data.apartId)}`
      }),
      providesTags: ['ClientAparts']
    }),
    creatClientApart: build.mutation<null, IClientForm>({
      query: (body) => ({
        url: '/apart-client',
        method: 'POST',
        body
      }),
      invalidatesTags: ['ClientAparts']
    }),
    updateClientApart: build.mutation<null, IClientUpdateForm>({
      query: (data) => ({
        url: '/apart-client',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ClientAparts']
    }),
    deleteClientApart: build.mutation<null, IClientForm>({
      query: ({ apartId, clientId }) => ({
        url: '/apart-client/',
        method: 'DELETE',
        params: { apartId, clientId }
      }),
      invalidatesTags: ['ClientAparts']
    })
  })
});

export const {
  useGetClientApartsByClientIdQuery,
  useDeleteClientApartMutation,
  useCreatClientApartMutation,
  useGetClientApartByApartIdQuery,
  useUpdateClientApartMutation,
  useGetClientApartQuery,
  useGetClientApartHistoryQuery,
  useGetClientApartResidentsQuery
} = clientApartsAPI;
