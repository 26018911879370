import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  textFieldRoot: {
    display: 'flex',
    backgroundColor: `${theme.palette.black['3']} !important`,
    borderRadius: '12px !important',
    '& fieldset': {
      border: 'none'
    }
  },
  root: {
    width: '100%',
    padding: '12px !important',
    '& fieldset': {
      border: 'none'
    },
    '& div': {
      padding: '0px !important'
    }
  },
  wrapper: {
    border: 'none',
    padding: '0 !important'
  },
  inputLabel: {
    left: '10px',
    transform: 'translate(12px, 10px) scale(1)',
    fontSize: '16px',
    lineHeight: '28px',
    color: `${theme.palette.black['60']} !important`
  },
  inputLabelShrink: {
    transform: 'translate(12px, 0px) scale(0.75)',
    color: `${theme.palette.black['60']} !important`
  },
  input: {
    height: '28px'
  },
  menuRoot: {
    top: '5px',
    padding: 0,
    '& ul': {
      padding: '0px',
      maxHeight: '350px'
    }
  },
  list: {
    maxHeight: '350px',
    overflow: 'auto',
    boxShadow: 'unset',
    borderRadius: '12px',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '100px',
      border: '2px solid transparent',
      backgroundColor: theme.palette.black['20'],
      backgroundClip: 'content-box'
    },
    '&::-webkit-scrollbar-track': {
      margin: '5px',
      background: 'transparent'
    },
    '::-webkit-scrollbar-corner': {
      background: 'transparent'
    }
  },
  menuPaper: {
    display: 'block',
    position: 'relative',
    top: '5px',
    overflow: 'hidden',
    borderRadius: '12px',
    maxHeight: '350px',
    boxShadow: 'unset'
  },
  errorMenuPaper: {
    outline: `1px solid ${theme.palette.error.main} !important`
  },
  color: {
    color: `${theme.palette.black['60']} !important`
  },
  background: {
    backgroundColor: `${theme.palette.black['3']} !important`
  },
  hidden: {
    opacity: 0
  },
  error: {
    backgroundColor: `${theme.palette.error.light} !important`,
    outline: `1px solid ${theme.palette.error.main} !important`,
    borderRadius: '12px !important'
  },
  option: {
    padding: '11px 24px',
    color: theme.palette.black['90'],
    backgroundColor: 'transparent !important',
    lineHeight: '28px',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: `${theme.palette.black['10']} !important`
    },
    '&:not(:first-child)': {
      borderTop: `1px solid ${theme.palette.black['10']}`
    }
  },
  disabledLabel: {
    color: 'rgba(187, 187, 186, 1) !important'
  },
  inputRoot: {
    padding: '18px 70px 3px 18px !important'
  },
  endAdornment: {
    marginRight: '16px'
  }
}));
