interface IProps {
  firstName?: string;
  lastName?: string;
  patronymic?: string;
}
export const getFullName = ({ firstName, lastName, patronymic }: IProps): string => {
  if (!lastName && !firstName && !patronymic) {
    return '';
  }

  return `${String(lastName ?? '')} ${String(firstName ?? '')} ${String(patronymic ?? '')}`;
};
