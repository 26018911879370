import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  tabs: {
    background: theme.palette.black['3'],
    borderRadius: '50px',
    '& .MuiTabs-indicator': {
      background: 'none'
    },
    [theme.breakpoints.down('desktop')]: {
      height: '44px'
    }
  },
  tab: {
    borderRadius: '50px',
    margin: '6px',
    height: '18px',
    minHeight: '36px',
    padding: '10px 20px',
    ':not(:last-of-type)': {
      marginRight: '0px'
    },
    [theme.breakpoints.down('desktop')]: {
      fontSize: '14px',
      height: '34px',
      padding: '10px'
    }
  },
  activeTab: {
    background: theme.palette.common.white,
    boxShadow: '0px 8px 20px rgba(47, 83, 135, 0.1)'
  }
}));
