import { createApi } from '@reduxjs/toolkit/query/react';
import { IApartBillForm, IBill, IBillForm } from 'typings/bill';
import { customFetchBase } from '../fetchBase';
import { IEditQuery } from '../../typings/app';

export const billApi = createApi({
  reducerPath: 'billApi',
  baseQuery: customFetchBase,
  tagTypes: ['Bill'],
  endpoints: (build) => ({
    postBill: build.mutation<IBill, IBillForm>({
      query: ({ houseId, file }) => ({
        url: `bill/${String(houseId)}`,
        method: 'POST',
        body: file,
        formData: true
      }),
      invalidatesTags: ['Bill']
    }),
    postApartBill: build.mutation<IBill, IApartBillForm>({
      query: (body) => ({
        url: 'bill',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Bill']
    }),
    putApartBill: build.mutation<IBill, IEditQuery<IApartBillForm>>({
      query: (data) => ({
        url: `bill/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Bill']
    })
  })
});

export const { usePostBillMutation, usePostApartBillMutation, usePutApartBillMutation } =
  billApi;
