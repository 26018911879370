import React from 'react';
import { ReactComponent as Logo } from 'shared/assets/images/logo.svg';
import { useStyles } from './styles';

const Header = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.headerWrapper}>
      <div className={classes.header}>
        <Logo className={classes.logo} />
      </div>
    </div>
  );
};

export default Header;
