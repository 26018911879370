import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ExitIcon from '@mui/icons-material/ExitToApp';
import { ROUTE } from 'shared/config/routes';
import { SmallNavigationItem } from './navigationItem/SmallNavigationItem';
import { useStyles } from './Navigation.styles';
import { navigationList } from '../navigationList';

interface IProps {
  logoutHandler: () => void;
  open: boolean;
}

export const SmallNavigation = ({ logoutHandler, open }: IProps) => {
  const { classes } = useStyles({ open });

  return (
    <nav className={classes.navigation}>
      <div>
        {navigationList.map(({ title, link, Icon, id, subNavigation }) => (
          <SmallNavigationItem
            key={title}
            title={open ? title : ''}
            link={link}
            Icon={Icon}
            id={id}
            subNavigation={subNavigation}
          />
        ))}
      </div>
      <div>
        <SmallNavigationItem
          link={ROUTE.PERSONAL_CABINET}
          id={'personal'}
          title={open ? 'Личный кабинет' : ''}
          Icon={PersonIcon}
        />
        <SmallNavigationItem
          onClick={logoutHandler}
          title={open ? 'Выйти' : ''}
          Icon={ExitIcon}
          dark
        />
      </div>
    </nav>
  );
};
