import React, { useMemo } from 'react';
import { BaseTextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Control } from 'react-hook-form';
import { IOption } from 'typings/app';
import { CircularProgress } from '@mui/material';
import { useStyles } from './SearchSelect.styles';

interface IProps extends BaseTextFieldProps {
  options: IOption[];
  control: Control<any>;
  label: string;
  name: string;
  defaultValue?: string;
  loading?: boolean;
  getData: () => void;
  hasMore: boolean;
}
type TValue = string | number | boolean;

interface OptionProps {
  option: IOption;
  value: TValue | TValue[];
  loading: IProps['loading'];
}

const emptyOptions = [
  { id: 0, value: '0', label: 'По  вашему  запросу ничего не найдено' }
];
const loadingOption = { id: 0, value: 'loading', label: 'loading' };

export const Option = ({ option, value, loading, ...props }: OptionProps) => {
  const { classes, cx } = useStyles();
  const isEmpty = option.label === emptyOptions[0].label;
  const isLoading = option.label === loadingOption.label;
  const isSelected = useMemo(() => {
    if (Array.isArray(value)) {
      return value.includes(option.value)
    }
    return value === option.value
  }, [value, option])

  return (
    <MenuItem
      key={option.id}
      value={String(option.value)}
      classes={{
        root: cx(
          isEmpty && classes.emptyOption,
          !isEmpty && classes.option,
            isSelected && classes.activeOption
        )
      }}
      {...props}
    >
      {!isLoading ? option.label : <CircularProgress size={20} />}
    </MenuItem>
  );
};
