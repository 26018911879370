import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  title: {
    color: theme.palette.status.primary,
    fontSize: '14px',
    fontWeight: '400'
  },
  link: {
    fontSize: '14px',
    fontWeight: '400'
  },
  crumbs: {
    fontSize: '14px',
    fontWeight: '400',
    '.MuiBreadcrumbs-separator': {
      margin: '0 4px'
    }
  }
}));
