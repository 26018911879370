import React from 'react';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MUIModal from '@mui/material/Modal';
import { Button } from 'shared/ui/index';
import { ReactComponent as LogoutIcon } from 'shared/assets/images/logout.svg';
import { IModalProps } from '../Modal';
import { useStyles } from './styles';

interface IProps extends Omit<IModalProps, 'children' | 'title'> {
  handleSubmit: any;
  isLoading: boolean;
}

export const LogoutModal = ({ isOpen, onClose, handleSubmit, isLoading }: IProps) => {
  const { classes } = useStyles();
  return (
    <MUIModal open={isOpen} onClose={onClose}>
      <div className={classes.modal}>
        <div className={classes.body}>
          <IconButton
            className={classes.closeIcon}
            onClick={onClose}
            disabled={isLoading}
            disableTouchRipple
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.title}>
            <div className={classes.titleIconWrapper}>
              <LogoutIcon />
            </div>
            <Typography variant="modalTitle" color="black.90">
              Выход
            </Typography>
          </div>
          <Typography variant="formMedium" color="black.40" fontWeight={400}>
            Вы уверены, что хотите выйти из системы ?
          </Typography>
        </div>

        <div className={classes.bottom}>
          <Button
            className={classes.button}
            color="secondary"
            onClick={onClose}
            disabled={isLoading}
          >
            Отмена
          </Button>
          <Button className={classes.button} onClick={handleSubmit} loading={isLoading}>
            Выйти
          </Button>
        </div>
      </div>
    </MUIModal>
  );
};
