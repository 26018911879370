import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    borderRadius: '10px',
    outline: 'none',
    maxWidth: '99vw',
    maxHeight: '99%'
  },
  body: {
    position: 'relative',
    padding: '40px 30px'
  },
  closeIcon: {
    position: 'absolute',
    right: '24px',
    top: '24px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    marginBottom: '10px',
    width: 'inherit'
  },
  titleIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    borderRadius: '100%',
    background: theme.palette.error.light
  },
  titleIcon: {
    color: theme.palette.error.main
  },
  bottom: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
    padding: '20px',
    borderTop: `1px solid ${theme.palette.black['10']}`
  },
  button: {
    minWidth: '140px'
  },
  text: {
    wordBreak: 'break-word'
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '485px'
  },
  doneIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    borderRadius: '100%',
    background: '#EAFFF2'
  },
  doneIcon: {
    color: theme.palette.success.main
  },
  confirmButton: {
    paddingLeft: '50px',
    paddingRight: '50px'
  }
}));
