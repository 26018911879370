import React from 'react';
import MUITableCell from '@mui/material/TableCell';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useStyles } from './TableHeadItem.styles';

interface IProps {
  children?: React.ReactNode;
  width?: string;
  description?: boolean;
}

export const TableHeadItem = ({ children, width, description }: IProps) => {
  const { classes } = useStyles({ width });
  return (
    <MUITableCell classes={{ root: classes.headCell }}>
      <div className={classes.cellInner}>
        {children}
        {description && <HelpIcon className={classes.cellIcon} />}
      </div>
    </MUITableCell>
  );
};
