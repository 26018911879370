import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

export const useStyles = makeStyles<{ fullWidth?: boolean }>()((theme, props) => ({
  wrapper: {
    overflow: 'auto',
    borderRadius: '24px',
    boxShadow: `0px 8px 20px ${alpha(theme.palette.black['100'], 0.1)}`,

    '&::-webkit-scrollbar': {
      width: '16px',
      height: '16px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '100px',
      border: '4px solid transparent',
      backgroundColor: theme.palette.black['20'],
      backgroundClip: 'content-box'
    },
    '&::-webkit-scrollbar-track': {
      margin: '15px',
      background: 'transparent'
    },
    '::-webkit-scrollbar-corner': {
      background: 'transparent'
    }
  },
  growWrapper: {
    flexGrow: 1
  },
  table: {
    tableLayout: 'fixed',
    width: props.fullWidth ? '100%' : 'auto',
    borderCollapse: 'separate',
    borderSpacing: 0
  },
  head: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    '& th': {
      borderBottom: '1px solid',
      borderColor: theme.palette.black['10']
    }
  },
  emptyInfo: {
    textAlign: 'left',
    padding: '24px',
    '& >div': {
      padding: '15px 11px',
      backgroundColor: theme.palette.black['3']
    }
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  actionButton: {
    marginTop: '12px',
    width: 'max-content',
    marginLeft: 'auto'
  }
}));
