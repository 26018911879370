import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IReceiptLoad,
  IReceiptForm,
  IReceipt,
  IUploadReceiptForm
} from 'typings/receipt';
import { IQueryPaginationParams, IResponseWithPagination } from 'typings/app';
import { customFetchBase } from '../fetchBase';

export const receiptApi = createApi({
  reducerPath: 'receiptApi',
  baseQuery: customFetchBase,
  tagTypes: ['Receipt'],
  endpoints: (build) => ({
    postReceipt: build.mutation<IReceiptLoad, IReceiptForm>({
      query: ({ houseId, file }) => ({
        url: `/receipt/${String(houseId)}`,
        method: 'POST',
        body: file,
        formData: true
      }),
      invalidatesTags: ['Receipt']
    }),
    uploadReceiptFile: build.mutation<IReceiptLoad, IUploadReceiptForm>({
      query: ({ apartId, file }) => ({
        url: `/receipt/file/${String(apartId)}`,
        method: 'POST',
        body: file,
        formData: true
      }),
      invalidatesTags: ['Receipt']
    }),
    getReceipt: build.query<IResponseWithPagination<IReceipt>, IQueryPaginationParams>({
      query: ({ id, page, limit }) => ({
        url: `/receipt/${String(id)}`,
        params: { page, limit }
      }),
      providesTags: ['Receipt']
    }),
    deleteReceipt: build.mutation<null, number>({
      query: (receiptId) => ({
        url: `/receipt/${receiptId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Receipt']
    })
  })
});

export const {
  usePostReceiptMutation,
  useGetReceiptQuery,
  useDeleteReceiptMutation,
  useUploadReceiptFileMutation
} = receiptApi;
