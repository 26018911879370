import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ICheckSmsForm,
  ICheckVerifyValues,
  ICreatePassForm,
  IRecoveryPasswordForm,
  ISignInForm,
  ISignInResponse,
  IUser
} from 'typings/auth';
import { customFetchBase } from '../fetchBase';

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['authMe'],
  endpoints: (build) => ({
    signIn: build.mutation<ISignInResponse, ISignInForm>({
      query: (data) => ({
        url: '/auth/login/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['authMe']
    }),
    authMe: build.query<IUser, void>({
      query: () => ({
        url: '/auth/me/'
      })
    }),
    logout: build.mutation<null, void>({
      query: () => ({
        url: '/auth/logout/',
        method: 'POST'
      })
    }),
    checkToken: build.mutation<null, ICheckVerifyValues>({
      query: (body) => ({
        url: '/auth/check/',
        method: 'POST',
        body
      })
    }),
    getSmsCode: build.mutation<null, ICheckVerifyValues>({
      query: (body) => ({
        url: '/auth/phoneConfirm/',
        method: 'POST',
        body
      })
    }),
    checkSmsCode: build.mutation<null, ICheckSmsForm>({
      query: (body) => ({
        url: '/auth/phoneSMS/',
        method: 'POST',
        body
      })
    }),
    createPass: build.mutation<null, ICreatePassForm>({
      query: (body) => ({
        url: '/auth/createPass/',
        method: 'POST',
        body
      })
    }),
    recoveryPassword: build.mutation<null, IRecoveryPasswordForm>({
      query: (data) => ({
        url: '/auth/resetPass',
        method: 'POST',
        body: data
      })
    }),
    sendFirebaseToken: build.mutation<null, string>({
      query: (data) => ({
        url: '/user/token',
        method: 'POST',
        body: {
          push_token: data
        }
      })
    })
  })
});

export const {
  useSignInMutation,
  useLazyAuthMeQuery,
  useCheckTokenMutation,
  useGetSmsCodeMutation,
  useCheckSmsCodeMutation,
  useCreatePassMutation,
  useLogoutMutation,
  useRecoveryPasswordMutation,
  useAuthMeQuery,
  useSendFirebaseTokenMutation
} = authAPI;
