import { Control, useController } from 'react-hook-form';
import React, { useState } from 'react';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useStyles } from './Input.styles';

export interface IInputProps extends BaseTextFieldProps {
  name: string;
  label: string;
  control: Control<any>;
  variant?: 'filled' | 'standard' | 'outlined';
  color?: 'primary' | 'secondary';
  defaultValue?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
}

export const Input = ({
  name,
  control,
  variant = 'filled',
  color = 'primary',
  defaultValue,
  startIcon,
  endIcon,
  hidden,
  multiline,
  className,
  ...rest
}: IInputProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const makeAnimationStartHandler =
    (stateSetter: (flag: boolean) => void) => (e: any) => {
      const autofilled = !!e.target?.matches('*:-webkit-autofill');
      if (e.animationName === 'mui-auto-fill') {
        stateSetter(autofilled);
      }

      if (e.animationName === 'mui-auto-fill-cancel') {
        stateSetter(autofilled);
      }
    };

  const {
    field: { value, onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  });
  const { classes, cx } = useStyles({
    startIcon: !!startIcon,
    endIcon: !!endIcon,
    hidden: !!hidden,
    multiline
  });
  const onFocus = () => setFocused(true);
  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0) setFocused(false);
  };

  return (
    <div className={classes.wrapper}>
      <TextField
        value={value ?? ''}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        variant={variant}
        classes={{ root: classes.textField }}
        InputLabelProps={{
          classes: {
            root: cx(classes.inputLabel, classes[color]),
            shrink: classes.inputLabelShrink
          },
          shrink: !!value || focused,
          focused
        }}
        inputProps={{
          onAnimationStart: makeAnimationStartHandler(setFocused)
        }}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start" classes={{ root: classes.adornment }}>
              {startIcon}
            </InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end" classes={{ root: classes.adornment }}>
              {endIcon}
            </InputAdornment>
          ),
          ...(variant === 'filled' && {
            disableUnderline: true,
            classes: {
              root: cx(classes.root, classes[color], invalid && classes.error, className),
              focused: cx(classes.rootFocused, classes[color])
            }
          })
        }}
        multiline={multiline}
        {...rest}
      />
      {error && (
        <Typography variant="formError" component="div" color="error.main">
          {error?.message}
        </Typography>
      )}
    </div>
  );
};
