import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ width?: string; verticalAlign?: string }>()(
  (theme, props) => ({
    bodyCell: {
      border: 'unset',
      color: theme.palette.black['90'],
      width: props?.width,
      maxWidth: props?.width,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: '11px 16px',
      verticalAlign: props.verticalAlign
    }
  })
);
