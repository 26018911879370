import React from 'react';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useStyles } from './styles';

interface IProps {
  title: string;
  subtitle?: string;
  type?: 'warning' | 'error';
}

export const Alert = ({ title, subtitle, type = 'warning' }: IProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.iconWrapper}>
        <ErrorOutlineIcon className={classes.icon} />
      </div>
      <div className={classes.content}>
        <Typography variant="smallButton" component="div" color="warning.main">
          {title}
        </Typography>
        <Typography
          variant="tabBar"
          component="div"
          color="black.60"
          whiteSpace="break-spaces"
        >
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};
