import React from 'react';
import { ReactComponent as Logo } from 'shared/assets/images/logo.svg';
import { useStyles } from './initLoading.styles';

interface IProps {
  isShow: boolean;
}
export const InitLoading = ({ isShow }: IProps) => {
  const { classes } = useStyles();

  if (!isShow) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Logo className={classes.logo} />
    </div>
  );
};
