import { IUser } from 'typings/auth';
import { AccessesValuesType } from 'typings/accesses';
import { ACCESSES_OPERATION } from './accessesList';

export const isAvailableCatalogs = (
  user: IUser,
  catalogs: AccessesValuesType[]
): boolean => {
  if (user?.role?.accesses.includes('god')) {
    return true;
  }
  const getCatalogsAccesses = catalogs.map(
    (catalog) => `${catalog}_${ACCESSES_OPERATION.get}`
  );

  return !!user?.role?.accesses?.some((access) => getCatalogsAccesses.includes(access));
};
