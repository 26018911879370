import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { isAvailableCatalog, isAvailableCatalogs } from 'widgets/layouts';
import { getUserInfo } from 'store/auth';
import { AccessesValuesType } from 'typings/accesses';
import { theme } from 'shared/config/theme';
import { useStyles } from './SmallNavigationItem.styles';

interface IProps {
  title: string;
  link?: string;
  id?: string;
  Icon: React.ElementType;
  subNavigation?: ISubNavigation[];
  dark?: boolean;
  onClick?: () => void;
  setExpanded?: (data: false | string) => void;
}

interface ISubNavigation {
  title: string;
  link: string;
  accessKey: AccessesValuesType;
}

export const SmallNavigationItem = ({
  title,
  link,
  Icon,
  subNavigation,
  dark,
  onClick,
  id,
  setExpanded
}: IProps) => {
  const { pathname } = useLocation();
  const user = useSelector(getUserInfo);
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useLayoutEffect(() => {
    const isAccordionNavItem = !!subNavigation;
    if (isAccordionNavItem && setExpanded && id) {
      subNavigation.forEach((item) => {
        if (pathname.includes(item.link)) setExpanded(id);
      });
    }
  }, []);

  const onClickLinkHandler = () => {
    onClick?.();
    setExpanded?.(id ?? false);
  };
  if (!subNavigation) {
    return (
      <Link
        onClick={onClickLinkHandler}
        to={link as string}
        className={cx(
          classes.accordionItem,
          classes.padding,
          classes.hovered,
          dark && classes.dark,
          pathname.includes(link as string) && classes.active
        )}
      >
        <Icon />
        <Typography variant="formMedium">{title}</Typography>
      </Link>
    );
  }
  if (subNavigation) {
    const isNotAvailableSubNavigation = !isAvailableCatalogs(
      user,
      subNavigation.map((item) => item.accessKey)
    );
    if (isNotAvailableSubNavigation) return null;
  }

  return (
    <div className={classes.wrapper}>
      <IconButton
        size="small"
        className={classes.iconButton}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        component="a"
        onClick={handleClick}
      >
        <Icon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        classes={{ root: classes.accordionDetails }}
        PaperProps={{
          style: {
            background: theme.palette.gradient.dark,
            marginLeft: '66px',
            borderRadius: '12px'
          }
        }}
        className={classes.menu}
      >
        {subNavigation.map((item) => {
          const isNotAvailable = !isAvailableCatalog(user, item.accessKey);
          return (
            <MenuItem className={classes.menuItem} key={item.title} onClick={handleClose}>
              <Link
                to={item.link}
                className={cx(
                  classes.accordionSubItem,
                  classes.hovered,
                  pathname.includes(item.link) && classes.active,
                  isNotAvailable && classes.hidden
                )}
              >
                {item.title}
              </Link>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
