import { createApi } from '@reduxjs/toolkit/query/react';
import { IComplex, IComplexesForm } from 'typings/complexes';
import { IEditQuery, IQueryPaginationParams, IResponseWithPagination } from 'typings/app';
import { customFetchBase } from '../fetchBase';

export const complexAPI = createApi({
  reducerPath: 'complexAPI',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['Complex'],
  endpoints: (build) => ({
    getComplexes: build.query<IResponseWithPagination<IComplex>, IQueryPaginationParams>({
      query: (params) => ({
        url: '/complex/',
        params
      }),
      providesTags: ['Complex']
    }),
    getComplex: build.query<IComplex, number>({
      query: (id) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `/complex/${id}`
      }),
      providesTags: ['Complex']
    }),
    createComplex: build.mutation<null, IComplexesForm>({
      query: (body) => ({
        url: '/complex/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Complex']
    }),
    updateComplex: build.mutation<null, IEditQuery<IComplexesForm>>({
      query: (data) => ({
        url: `/complex/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Complex']
    }),
    deleteComplex: build.mutation<null, number>({
      query: (id) => ({
        url: `/complex/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Complex']
    })
  })
});

export const {
  useGetComplexesQuery,
  useCreateComplexMutation,
  useDeleteComplexMutation,
  useUpdateComplexMutation,
  useGetComplexQuery
} = complexAPI;
