import { Control, useController } from 'react-hook-form';
import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useStyles } from './CodeInput.styles';
import { CodeTextField } from './CodeTextField';

interface IInputProps {
  name: string;
  control: Control<any>;
  defaultValue?: string;
  fields?: number;
}

export const CodeInput = ({ name, control, defaultValue, fields = 4 }: IInputProps) => {
  const { classes } = useStyles({});
  const [inputs, setInputs] = useState(Array(fields).fill({ value: null }));
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const {
    field: { onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  });

  const onChangeFocus = (currentIndex: number) => {
    const maxIndex = fields - 1;
    if (currentIndex <= 0) {
      setCurrentIndex(0);
    }
    if (currentIndex === maxIndex) {
      setCurrentIndex(maxIndex);
    }
    if (currentIndex >= 0 && currentIndex < maxIndex) {
      setCurrentIndex(currentIndex);
    }
  };
  const onChangeValue = (currentIndex: number, currentValue: number | null) => {
    const newArr = inputs.map((i, index) => ({
      ...i,
      value: index === currentIndex ? currentValue : i.value
    }));
    setInputs(newArr);
    onChange(
      newArr
        .filter((i) => i.value || i.value === 0)
        .map((i) => i.value)
        .join('')
    );
    onChangeFocus(currentIndex + 1);
  };
  const isDetectDisabled = useCallback(
    (index: number) => !inputs[index - 1]?.value && inputs[index - 1]?.value !== 0 && index !== 0,
    [currentIndex, inputs]
  );
  return (
    <div className={classes.wrapper}>
      <div className={classes.inputsContainer}>
        {inputs.map((i, index) => (
          <CodeTextField
            currentIndex={currentIndex}
            onChangeValue={onChangeValue}
            key={index}
            onChangeFocus={onChangeFocus}
            index={index}
            invalid={invalid}
            disabled={isDetectDisabled(index)}
          />
        ))}
      </div>
      {error && (
        <Typography variant="formError" component="div" color="error.main">
          {error?.message}
        </Typography>
      )}
    </div>
  );
};
