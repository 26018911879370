import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query/react';
import { Mutex } from 'async-mutex';
import ENV from 'shared/config/env';
import { JWTToken, noAccessErrorHandler } from 'shared/utils';

const baseURL = ENV.API_URL;
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers) => {
    const token = JWTToken.getAccess();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});
export const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if ((result.error?.status as any) === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = JWTToken.getRefresh();
        if (refreshToken) {
          JWTToken.removeTokens();
          const refreshResult: any = await baseQuery(
            {
              url: '/auth/updateToken',
              method: 'POST',
              body: { token: refreshToken }
            },
            api,
            extraOptions
          );

          if (refreshResult?.data?.access_token && refreshResult?.data?.refresh_token) {
            JWTToken.setTokens(
              refreshResult.data.access_token as string,
              refreshResult.data.refresh_token as string
            );
            result = await baseQuery(args, api, extraOptions);
          } else {
            JWTToken.removeTokens();
            window.location.reload();
          }
        } else {
          JWTToken.removeTokens();
          window.location.reload();
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  if ((result.error?.status as any) === 403) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      noAccessErrorHandler((result.error?.data as any)?.message);
      release();
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
