import { Control, useController } from 'react-hook-form';
import React from 'react';
import Typography from '@mui/material/Typography';
import { RadioGroup } from '@mui/material';
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';

export interface IRadioButtonGroup extends RadioGroupProps {
  name: string;
  label: string;
  control: Control<any>;
  defaultValue?: string;
}

export const RadioInputGroup = ({
  name,
  control,
  defaultValue,
  ...rest
}: IRadioButtonGroup) => {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue
  });
  return (
    <div>
      <RadioGroup
        value={value}
        onChange={(_, value) => {
          // by default a radio input with value = true is converted to the string 'true'
          if (value === 'true') onChange(true);
          else if (value === 'false') onChange(false);
          else onChange(value);
        }}
        {...rest}
      />
      {error && (
        <Typography variant="formError" component="div" color="error.main">
          {error?.message}
        </Typography>
      )}
    </div>
  );
};
