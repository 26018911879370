import React from 'react';
import MUIPagination, { PaginationProps } from '@mui/material/Pagination';
import { useStyles } from './Pagination.styles';

export const Pagination = (props: PaginationProps) => {
  const { classes } = useStyles();
  return (
    <MUIPagination
      {...props}
      shape="rounded"
      classes={{ root: classes.root, ul: classes.ul }}
    />
  );
};
