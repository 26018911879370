import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IEditQuery,
  IQueryPaginationParams,
  IQueryParams,
  IResponseWithPagination
} from 'typings/app';
import { IApart, IApartById, IApartForm } from 'typings/aparts';
import { customFetchBase } from '../fetchBase';

export const apartsAPI = createApi({
  reducerPath: 'apartsAPI',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['Apart'],
  endpoints: (build) => ({
    getAparts: build.query<IResponseWithPagination<IApart>, IQueryPaginationParams>({
      query: ({ id, ...params }) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `/house/${id}/apart/`,
        params
      }),
      providesTags: ['Apart']
    }),
    getApart: build.query<IApartById, IQueryParams>({
      query: ({ id }) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `/apart/${id}`
      }),
      providesTags: ['Apart']
    }),
    createApart: build.mutation<null, IApartForm>({
      query: (body) => ({
        url: '/apart/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Apart']
    }),
    updateApart: build.mutation<null, IEditQuery<IApartForm>>({
      query: (data) => ({
        url: `/apart/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Apart']
    }),
    deleteApart: build.mutation<null, number>({
      query: (id) => ({
        url: `/apart/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Apart']
    })
  })
});

export const {
  useCreateApartMutation,
  useDeleteApartMutation,
  useGetApartsQuery,
  useUpdateApartMutation,
  useGetApartQuery
} = apartsAPI;
