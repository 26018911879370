import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    color: theme.palette.common.white,
    boxShadow: 'unset',
    margin: '0px!important',
    position: 'relative',
    '&::before': {
      display: 'none'
    }
  },
  accordionIcon: {
    color: theme.palette.common.white
  },
  accordionItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'unset !important',
    cursor: 'pointer'
  },
  accordionDetails: {
    padding: 'unset !important'
  },
  accordionSubItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '46px',
    cursor: 'pointer'
  },
  accordionExpanded: {
    minHeight: 'unset'
  },
  hovered: {
    '&:hover': {
      background: alpha(theme.palette.common.white, 0.1)
    },
    '&[aria-expanded="true"]': {
      minHeight: 'unset'
    }
  },
  padding: {
    padding: '13px 0'
  },
  active: {
    position: 'relative',
    background: alpha(theme.palette.common.white, 0.1),
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '-1.5px',
      bottom: '-1.5px',
      width: '5px',
      background: theme.palette.common.white,
      borderRadius: '5px 0 0 5px'
    }
  },
  dark: {
    color: theme.palette.black['30'],
    '&:hover': {
      color: theme.palette.common.white
    }
  },
  hidden: {
    display: 'none'
  },
  iconButton: {
    color: theme.palette.common.white,
    padding: '13px 0'
  },
  menuPaper: {
    background: theme.palette.gradient.dark,
    marginLeft: '66px'
  },
  menu: {
    width: '100%',
    padding: '0'
  },
  menuItem: {
    width: '100%',
    minWidth: '184px',
    padding: '0'
  }
}));
