const routesHandler = {
  // @ts-ignore
  get(target, property) {
    const value = target[property];

    if (typeof value === 'object' && value !== null) {
      return new Proxy(value, routesHandler);
    }

    if (property === 'link') {
      // @ts-ignore
      // eslint-disable-next-line func-names
      return function (args) {
        let path = target.path || '';

        if (args) {
          for (const [key, value] of Object.entries(args)) {
            path = path.replaceAll(`:${key}`, value);
          }
        }

        return path;
      };
    }

    return value instanceof Function ? value.bind(target) : value;
  }
};

export enum ROUTE {
  MAIN = '/',
  SIGN_IN = '/sign-in',
  VERIFY = '/verify',
  CHECK_SMS = '/check-sms',
  CREATE_PASS = '/create-pass',
  RECOVERY_PASS = '/recovery-pass',
  USERS = '/users',
  COMPLEXES = '/complex',
  HOUSES = '/complex/house',
  APART = '/complex/house/apart',
  NOT_FOUND = '/404',
  PERSONAL_CABINET = '/personal',
  ROLES = '/roles',
  CREATE_ROLE = '/roles-create',
  EDITE_ROLE = '/edit-role',
  WATCH_ROLE = '/watch-role',
  EMPLOYEES = '/employees',
  REQUEST = '/request',
  NEWS = '/news',
  CREATE_NEWS = '/news/create',
  EDITE_NEWS = '/news/edite',
  QUIZ = '/quiz',
  CREATE_QUIZ = '/quiz/create',
  EDITE_QUIZ = '/quiz/edite',
  RESULT_QUIZ = '/quiz/result',
  VOTES = '/votes',
  CREATE_VOTE = '/vote/create',
  EDITE_VOTES = '/vote/edite',
  RESULT_VOTES = '/vote/result',
  METERS = '/meters',
  PRIVACY_POLICY = '/privacy-policy',
  CAMERAS = '/cameras',
  FAQ = '/faq',
  FAQ_WATCH = '/faq/watch',
  CONTACTS = '/contacts',
  CONTACT = '/contact'
}

export const ROUTES = new Proxy(
  {
    UI_KIT: {
      path: '/ui-kit'
    },
    ROLES: {
      path: ROUTE.ROLES
    },
    CREATE_ROLE: {
      path: ROUTE.CREATE_ROLE
    },
    EDITE_ROLE: {
      path: ROUTE.EDITE_ROLE
    },
    WATCH_ROLE: {
      path: ROUTE.WATCH_ROLE
    },
    SIGN_IN: {
      path: ROUTE.SIGN_IN
    },
    VERIFY: {
      path: ROUTE.VERIFY
    },
    CHECK_SMS: {
      path: ROUTE.CHECK_SMS
    },
    CREATE_PASS: {
      path: ROUTE.CREATE_PASS
    },
    RECOVERY_PASS: {
      path: ROUTE.RECOVERY_PASS
    },
    MAIN: {
      path: ROUTE.MAIN
    },
    EMPLOYEES: {
      path: ROUTE.EMPLOYEES
    },
    EMPLOYEE: {
      path: `${ROUTE.EMPLOYEES}/:id`
    },
    NOT_FOUND_PAGE: {
      path: ROUTE.NOT_FOUND
    },
    PERSONAL_CABINET: {
      path: ROUTE.PERSONAL_CABINET
    },
    USERS: {
      path: ROUTE.USERS
    },
    USER: {
      path: `${ROUTE.USERS}/:id`
    },
    COMPLEXES: {
      path: ROUTE.COMPLEXES
    },
    HOUSES: {
      path: `${ROUTE.COMPLEXES}/:id`
    },
    APARTS: {
      path: `${ROUTE.HOUSES}/:id`
    },
    APART: {
      path: `${ROUTE.APART}/:id`
    },
    REQUESTS: {
      path: ROUTE.REQUEST
    },
    REQUEST: {
      path: `${ROUTE.REQUEST}/:id`
    },
    NEWS: {
      path: ROUTE.NEWS
    },
    CREATE_NEWS: {
      path: ROUTE.CREATE_NEWS
    },
    EDITE_NEWS: {
      path: ROUTE.EDITE_NEWS
    },
    QUIZ: {
      path: ROUTE.QUIZ
    },
    CREATE_QUIZ: {
      path: ROUTE.CREATE_QUIZ
    },
    EDITE_QUIZ: {
      path: ROUTE.EDITE_QUIZ
    },
    RESULT_QUIZ: {
      path: ROUTE.RESULT_QUIZ
    },
    VOTES: {
      path: ROUTE.VOTES
    },
    CREATE_VOTE: {
      path: ROUTE.CREATE_VOTE
    },
    EDITE_VOTE: {
      path: ROUTE.EDITE_VOTES
    },
    RESULT_VOTE: {
      path: ROUTE.RESULT_VOTES
    },
    METERS: {
      path: ROUTE.METERS
    },
    CAMERAS_COMPLEXES: {
      path: ROUTE.CAMERAS
    },
    CAMERAS: {
      path: `${ROUTE.CAMERAS}/:id`
    },
    FAQ: {
      path: `${ROUTE.FAQ}`
    },
    FAQ_WATCH: {
      path: `${ROUTE.FAQ_WATCH}/:id`
    },
    CONTACTS_COMPLEXES: {
      path: `${ROUTE.CONTACTS}`
    },
    CONTACTS: {
      path: `${ROUTE.CONTACTS}/:id`
    },
    CONTACT: {
      path: `${ROUTE.CONTACT}/:id`
    },
    PRIVACY_POLICY: {
      path: ROUTE.PRIVACY_POLICY
    }
  },
  routesHandler
);
