import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ open: boolean }>()((theme, props) => ({
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: props.open ? 'center' : 'space-between',
    flexGrow: 1,
    width: '100%'
  }
}));
