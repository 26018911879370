import { NOTIFICATION_TYPE } from 'typings/app';
import { ROUTE } from '../../config';

export const NOTIFICATION_DATA = {
  [NOTIFICATION_TYPE.REQUEST_NEW]: {
    title: 'Новая заявка от жильца',
    description: ' Просмотрите её, чтобы помочь решить вопрос.',
    redirect: `${ROUTE.REQUEST}?status=active`
  },
  [NOTIFICATION_TYPE.REQUEST_COMMENT]: {
    title: 'Новое сообщение по заявке <number>',
    description: 'Откройте переписку с жильцом по заявке.',
    redirect: `${ROUTE.REQUEST}/<number>?view=dialog`
  },
  [NOTIFICATION_TYPE.REQUEST_DONE]: {
    title: 'Заявка <number> закрыта успешно',
    description: 'Пользователь закрыл заявку.',
    redirect: `${ROUTE.REQUEST}/<number>?view=request`
  },
  [NOTIFICATION_TYPE.REQUEST_RETURN]: {
    title: 'Заявка <number> открыта повторно',
    description: 'Пользователь посчитал заявку нерешённой и вернул её в работу',
    redirect: `${ROUTE.REQUEST}/<number>?view=request`
  },
  [NOTIFICATION_TYPE.REQUEST_RATING]: {
    title: 'Получена оценка по заявке <number>',
    description: 'Пользователь оценил выполненную работу.',
    redirect: `${ROUTE.REQUEST}/<number>?view=request`
  }
};
