import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditQuery, IResponseWithPagination } from 'typings/app';
import { ICamera, ICamerasWithFilters, ICreateCameraForm } from 'typings/cameras';
import { customFetchBase } from '../fetchBase';

export const camerasAPI = createApi({
  reducerPath: 'camerasAPI',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['Cameras'],
  endpoints: (build) => ({
    getCameras: build.query<IResponseWithPagination<ICamera>, ICamerasWithFilters>({
      query: (params) => ({
        url: '/camera/',
        params
      }),
      providesTags: ['Cameras'],
      transformResponse(
        response: IResponseWithPagination<ICamera>
      ): IResponseWithPagination<ICamera> {
        return {
          ...response,
          data: response.data.map((el) => ({ ...el, isComplex: !el.address }))
        };
      }
    }),
    createCamera: build.mutation<null, ICreateCameraForm>({
      query: (body) => ({
        url: '/camera/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Cameras']
    }),
    updateCamera: build.mutation<null, IEditQuery<any>>({
      query: (data) => ({
        url: `/camera/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Cameras']
    }),
    deleteCamera: build.mutation<null, number>({
      query: (id) => ({
        url: `/camera/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Cameras']
    })
  })
});

export const {
  useGetCamerasQuery,
  useCreateCameraMutation,
  useDeleteCameraMutation,
  useUpdateCameraMutation
} = camerasAPI;
