import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IEditQuery,
  IMessageResponse,
  IQueryPaginationParams,
  IQueryParams
} from 'typings/app';
import {
  IEmployee,
  IEmployeeEditForm,
  IEmployeeForm,
  IEmployeesBackend
} from 'typings/employees';
import { customFetchBase } from '../fetchBase';

export const employeesAPI = createApi({
  reducerPath: 'employeesAPI',
  baseQuery: customFetchBase,
  tagTypes: ['Employees', 'Employee'],
  endpoints: (build) => ({
    getEmployees: build.query<IEmployeesBackend, IQueryPaginationParams>({
      query: (params) => ({
        url: '/user',
        params
      }),
      providesTags: ['Employees']
    }),
    getEmployee: build.query<IEmployee, IQueryParams>({
      query: ({ id }) => ({
        url: `/user/${String(id)}`
      }),
      providesTags: ['Employee']
    }),
    deleteEmployee: build.mutation<IMessageResponse, IQueryParams>({
      query: (data) => ({
        url: `/user/${data.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Employees']
    }),
    createEmployee: build.mutation<IMessageResponse, IEmployeeForm>({
      query: (data) => ({
        url: '/user',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Employees']
    }),
    editEmployee: build.mutation<IMessageResponse, IEmployeeEditForm>({
      query: (data) => ({
        url: '/user/edit',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Employees']
    }),
    updateEmployee: build.mutation<IEmployee, IEditQuery<IEmployeeForm>>({
      query: (data) => ({
        url: `/user/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Employees']
    })
  })
});

export const {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useDeleteEmployeeMutation,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useEditEmployeeMutation
} = employeesAPI;
