import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from 'shared/config/theme';
import { router } from './router';
import { WithInit } from './providers';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer
        style={{
          right: '40px',
          bottom: '52px',
          padding: 'unset'
        }}
      />
      <WithInit>
        <RouterProvider router={router} />
      </WithInit>
    </ThemeProvider>
  );
};
