import { createSlice } from '@reduxjs/toolkit';
import { IUser } from 'typings/auth';
import { JWTToken } from 'shared/utils';
import { authAPI } from './auth.api';
import { RootStore } from '../store';

interface IInitialState {
  user: IUser;
  isAuth: boolean;
}

const initialState: IInitialState = {
  user: {} as IUser,
  isAuth: false
};
export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      authAPI.endpoints.signIn.matchFulfilled,
      (state, { payload, meta }) => {
        // eslint-disable-next-line chai-friendly/no-unused-expressions
        meta.arg.originalArgs.rememberMe
          ? JWTToken.setTokens(payload.access_token, payload.refresh_token, 'local')
          : JWTToken.setTokens(payload.access_token, payload.refresh_token, 'session');
        // eslint-disable-next-line no-param-reassign
        state.isAuth = true;
      }
    );
    builder.addMatcher(authAPI.endpoints.authMe.matchFulfilled, (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.user = payload;
      // eslint-disable-next-line no-param-reassign
      state.isAuth = true;
    });
    builder.addMatcher(authAPI.endpoints.authMe.matchRejected, (state) => {
      // eslint-disable-next-line no-param-reassign
      state.isAuth = false;
    });
    builder.addMatcher(authAPI.endpoints.logout.matchFulfilled, (state) => {
      // eslint-disable-next-line no-param-reassign
      state.isAuth = false;
      // eslint-disable-next-line no-param-reassign
      state.user = {} as IUser;
    });
  }
});

export const getIsAuth = (state: RootStore) => state.auth.isAuth;
export const getUserInfo = (state: RootStore) => state.auth.user;
