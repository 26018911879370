import React, { ReactElement, useMemo, useState } from 'react';
import TextField, { BaseTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import { Control, useController } from 'react-hook-form';
import { IOption } from 'typings/app';
import Typography from '@mui/material/Typography';
import { useStyles } from './SearchSelect.styles';
import { Option } from './Option';
import { ListboxComponent } from './ListboxComponent';

export interface IProps extends BaseTextFieldProps {
  options: IOption[];
  control: Control<any>;
  label: string;
  name: string;
  defaultValue?: string;
  loading?: boolean;
  onSearch?: (value: string) => void;
  onChangeData?: (data: IOption['value']) => void;
}

export const emptyOptions = [
  { key: '0', id: 0, value: '0', label: 'По  вашему  запросу ничего не найдено' }
];
export const loadingOption = {
  key: 'loading',
  id: 0,
  value: 'loading',
  label: 'loading'
};

export const SearchSelect = ({
  label,
  options,
  control,
  name,
  defaultValue,
  disabled,
  loading,
  onSearch,
  onChangeData
}: IProps) => {
  const [showLabel, setShowLabel] = useState<boolean>(false);
  const { classes, cx } = useStyles();
  const {
    field: { value, onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  });

  const optionsState = useMemo(() => {
    if (loading) {
      return [loadingOption];
    }

    if (!options?.length && !loading) {
      return emptyOptions;
    }

    return options.filter(({ label }) => label !== loadingOption.label);
  }, [loading, options, value]);

  return (
    <div>
      <Autocomplete
        sx={{
          input: cx(
            classes.input,
            classes.background,
            invalid && classes.error,
            disabled && classes.disabledSelect
          ),
          label: cx(!showLabel ? classes.inputLabel : classes.inputLabelShrink),
          icon: cx(classes.icon, classes.color, disabled && classes.disabledLabel)
        }}
        classes={{
          root: classes.textFieldRoot,
          paper: cx(
            classes.menuRoot,
            classes.menuPaper,
            classes.background,
            invalid && classes.errorMenuPaper
          )
        }}
        onInputChange={(event, newValue) => {
          const currentOption = options?.find(
            ({ id }) => String(id) === String(newValue)
          );

          if (currentOption) {
            setShowLabel(true);
          }

          if (!newValue) {
            onChange('');
            onSearch?.('');
            setShowLabel(false);
          }

          onSearch?.(currentOption?.label ?? newValue);
          onChange(currentOption?.label ?? newValue);
          // @ts-ignore
          onChangeData?.(event?.target?.value);
        }}
        popupIcon={<KeyboardArrowDownIcon />}
        disabled={disabled}
        value={value}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            onFocus={() => setShowLabel(true)}
            onBlur={() => {
              setShowLabel(!!(value || defaultValue));
              onSearch?.('');
            }}
            classes={{ root: classes.root }}
            InputProps={{ classes: { root: classes.wrapper } }}
            label={label}
            value={value}
            onChange={(event) => {
              onSearch?.(event.target.value);
              onChange(event.target.value);
            }}
            {...params}
          />
        )}
        ListboxComponent={({ children, onMouseDown, ...params }) => (
          <ListboxComponent
            onMouseDown={onMouseDown}
            {...params}
            key={params.id}
            loading={loading}
          >
            {children as ReactElement[]}
          </ListboxComponent>
        )}
        filterOptions={(optionsState) => optionsState}
        options={optionsState}
        renderOption={(props, option) => (
          <Option
            option={option}
            value={value}
            loading={loading}
            {...props}
            key={option.id}
          />
        )}
      />
      {error && (
        <Typography variant="formError" component="div" color="error.main">
          {error?.message}
        </Typography>
      )}
    </div>
  );
};
