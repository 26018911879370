import { createApi } from '@reduxjs/toolkit/query/react';
import { IMessageResponse, IQueryParams, IResponseWithPagination } from 'typings/app';
import {
  IApartSelect,
  IComment,
  ICommentForm,
  IGetArchive,
  IKanban,
  IKanbanForm,
  IPostCommonForm,
  IPostRequest,
  IPostRequestForm,
  IRequest,
  IRequestComments,
  IRequestHistory,
  IResponsibleForm,
  IType,
  RequestTypes
} from 'typings/request';
import { customFetchBase } from '../fetchBase';

export const requestApi = createApi({
  reducerPath: 'requestApi',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 0,
  tagTypes: ['Request', 'RequestComments'],
  endpoints: (build) => ({
    getKanban: build.query<IKanban[], IKanbanForm>({
      query: (params) => ({
        url: 'request/kanban',
        params
      }),
      providesTags: ['Request']
    }),
    getArchive: build.query<IResponseWithPagination<IRequest>, IGetArchive>({
      query: (params) => ({
        url: 'request/archive',
        params
      }),
      providesTags: ['Request']
    }),
    getRequest: build.query<IRequest, IQueryParams>({
      query: ({ id }) => ({
        url: `/request/${id}`
      }),
      providesTags: ['Request']
    }),
    getRequestHistory: build.query<IRequestHistory[], IQueryParams>({
      query: ({ id }) => ({
        url: `/request/history/${id}`
      }),
      providesTags: ['Request']
    }),
    getRequestTypes: build.query<Record<RequestTypes, IType>, void>({
      query: () => ({
        url: '/request/type'
      }),
      providesTags: ['Request']
    }),
    deleteRequest: build.mutation<IMessageResponse, IQueryParams>({
      query: (data) => ({
        url: `/request/${data.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Request']
    }),
    postRequestStatus: build.mutation<IMessageResponse, IPostRequestForm>({
      query: (data) => ({
        url: '/request/status',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Request']
    }),
    postRequestCommon: build.mutation<IMessageResponse, IPostCommonForm>({
      query: (data) => ({
        url: '/request/common',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Request']
    }),
    postRequest: build.mutation<IMessageResponse, IPostRequest>({
      query: (data) => ({
        url: '/request',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Request']
    }),
    postComment: build.mutation<IComment, ICommentForm>({
      query: (data) => ({
        url: '/comment',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['RequestComments']
    }),
    postOfficialComment: build.mutation<IComment, ICommentForm>({
      query: (data) => ({
        url: '/comment/official',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['RequestComments']
    }),
    setResponsible: build.mutation<IComment, IResponsibleForm>({
      query: (data) => ({
        url: '/request/resposible',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Request']
    }),
    getRequestComments: build.query<IRequestComments, IQueryParams>({
      query: ({ id }) => ({
        url: `/request/comments/${id}`
      }),
      providesTags: ['RequestComments']
    }),
    readComment: build.query<IMessageResponse, IQueryParams>({
      query: ({ id }) => ({
        url: `/request/read/${String(id)}`
      }),
      providesTags: ['Request']
    }),
    getApartSelect: build.query<IApartSelect[], number>({
      query: (houseId) => ({
        url: '/apart/select',
        params: { houseId }
      })
    })
  })
});

export const {
  useGetKanbanQuery,
  usePostRequestCommonMutation,
  useGetRequestTypesQuery,
  usePostRequestStatusMutation,
  useGetRequestQuery,
  useGetArchiveQuery,
  usePostCommentMutation,
  usePostOfficialCommentMutation,
  useSetResponsibleMutation,
  useGetRequestHistoryQuery,
  useGetRequestCommentsQuery,
  useReadCommentQuery,
  useGetApartSelectQuery
} = requestApi;
