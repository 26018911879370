import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditQuery, IQueryPaginationParams, IResponseWithPagination } from 'typings/app';
import {
  IPostQuizForm,
  IPutQuizForm,
  IPutQuizFormOption,
  IQuiz,
  IQuizFormOption,
  IQuizResult
} from 'typings/quiz';
import { customFetchBase } from '../fetchBase';

export const quizApi = createApi({
  reducerPath: 'quizApi',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  tagTypes: ['Quiz', 'Quizes'],
  endpoints: (build) => ({
    getQuiz: build.query<IResponseWithPagination<IQuiz>, IQueryPaginationParams>({
      query: (params) => ({
        url: '/quiz',
        params
      }),
      providesTags: ['Quizes']
    }),
    getOneQuiz: build.query<IQuiz, number>({
      query: (id) => ({
        url: `/quiz/${id}`
      }),
      providesTags: ['Quiz']
    }),
    getQuizResult: build.query<IQuizResult, number>({
      query: (id) => ({
        url: `/quiz/result/${id}`
      }),
      providesTags: ['Quiz']
    }),
    createQuiz: build.mutation<null, IPostQuizForm>({
      query: (body) => ({
        url: '/quiz/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Quiz']
    }),
    createQuizOption: build.mutation<null, IQuizFormOption>({
      query: (body) => ({
        url: '/quiz/option',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Quiz']
    }),
    updateQuiz: build.mutation<null, IEditQuery<IPutQuizForm>>({
      query: (data) => ({
        url: `/quiz/${data.id}`,
        method: 'PUT',
        body: data.body
      }),
      invalidatesTags: ['Quiz']
    }),
    updateQuizOption: build.mutation<null, IPutQuizFormOption>({
      query: (data) => ({
        url: `/quiz/option/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Quiz']
    }),
    deleteQuiz: build.mutation<null, number>({
      query: (quizId) => ({
        url: `/quiz/${quizId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Quizes']
    }),
    deleteQuizOption: build.mutation<null, number>({
      query: (quizOptionId) => ({
        url: `/quiz/option/${quizOptionId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Quiz']
    })
  })
});

export const {
  useGetQuizQuery,
  useDeleteQuizMutation,
  useCreateQuizMutation,
  useGetOneQuizQuery,
  useGetQuizResultQuery,
  useUpdateQuizMutation,
  useUpdateQuizOptionMutation,
  useDeleteQuizOptionMutation,
  useCreateQuizOptionMutation
} = quizApi;
