import React from 'react';
import MUIModal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './Modal.styles';

export interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  className?: string;
}

export const Modal = ({ isOpen, onClose, children, title, className }: IModalProps) => {
  const { classes } = useStyles();
  return (
    <MUIModal className={className} open={isOpen} onClose={onClose}>
      <div className={classes.modal}>
        <div className={classes.body}>
          <IconButton className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          {title && (
            <div className={classes.title}>
              <Typography variant="modalTitle" color="black.90">
                {title}
              </Typography>
            </div>
          )}
          {children}
        </div>
      </div>
    </MUIModal>
  );
};
