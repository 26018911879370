import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsInit, setIsInit } from 'store/app';
import { useLazyAuthMeQuery } from 'store/auth';
import { InitLoading } from 'shared/ui';
import { JWTToken } from 'shared/utils';

interface IProps {
  children: React.ReactNode;
}

export const WithInit = ({ children }: IProps) => {
  const dispatch = useDispatch();
  const isInit = useSelector(getIsInit);
  const [authMe, { isLoading }] = useLazyAuthMeQuery();

  useEffect(() => {
    initializeApp();
  }, []);

  const initializeApp = async () => {
    const token = JWTToken.getAccess();
    if (token) {
      await authMe();
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    dispatch(setIsInit(true));
  };

  if (!isInit || isLoading) {
    return <InitLoading isShow={true} />;
  }

  return <>{children}</>;
};
