import { Control, useController } from 'react-hook-form';
import React, { ChangeEvent, useState } from 'react';
import InputMask from 'react-input-mask';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useStyles } from './Input.styles';

interface IInputProps extends BaseTextFieldProps {
  name: string;
  label: string;
  control: Control<any>;
  variant?: 'filled' | 'standard' | 'outlined';
  color?: 'primary' | 'secondary';
  defaultValue?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  mask?: string;
}

export const InputWithMask = ({
  name,
  control,
  variant = 'filled',
  color = 'primary',
  defaultValue,
  startIcon,
  hidden,
  label,
  mask = '+7 999 999-99-99',
  ...rest
}: IInputProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const {
    field: { value, onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  });
  const { classes, cx } = useStyles({ startIcon: !!startIcon, hidden: !!hidden });
  const onFocus = () => setFocused(true);
  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0) {
      setFocused(false);
      onChange('');
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newData = Number(e.target.value.replace(/[^0-9]/g, ''));
    onChange(newData);
  };

  return (
    <div>
      <InputMask
        className={classes.widthInput}
        alwaysShowMask={false}
        mask={mask}
        value={value ? value?.toString() : ''}
        maskChar=" "
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={(value) => onChangeHandler(value)}
      >
        <TextField
          label={label}
          variant={variant}
          classes={{ root: classes.wrapper }}
          InputLabelProps={{
            classes: {
              root: cx(classes.inputLabel, classes[color]),
              shrink: classes.inputLabelShrink
            },
            shrink: !!value || focused
          }}
          InputProps={{
            startAdornment: startIcon && (
              <InputAdornment position="start" classes={{ root: classes.adornment }}>
                {startIcon}
              </InputAdornment>
            ),
            ...(variant === 'filled' && {
              disableUnderline: true,
              classes: {
                root: cx(classes.root, classes[color], invalid && classes.error),
                focused: cx(classes.rootFocused, classes[color])
              }
            })
          }}
          fullWidth={rest.fullWidth}
        />
      </InputMask>
      {error && (
        <Typography variant="formError" component="div" color="error.main">
          {error?.message}
        </Typography>
      )}
    </div>
  );
};
