import React, { SyntheticEvent } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { IconButton } from '@mui/material';
import { LightTooltip } from 'shared/ui';
import { TableRowItem } from '../rowItem/TableRowItem';
import { useStyles } from './TableAction.styles';

interface IProps {
  onEdit?: () => void;
  onDelete?: () => void;
  onWatch?: () => void;
  onDownload?: () => void;
  onWatchTitle?: string;
  onEditTitle?: string;
  onDeleteTitle?: string;
  onDownloadTitle?: string;
  width?: string;
  verticalAlign?: string;
}

export const TableAction = ({
  onEdit,
  onDelete,
  onWatch,
  onWatchTitle,
  onDownload,
  onEditTitle,
  onDeleteTitle,
  onDownloadTitle,
  verticalAlign
}: IProps) => {
  const { classes } = useStyles();

  const handleEdite = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (onEdit) {
      onEdit();
    }
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (onDelete) {
      onDelete();
    }
  };

  const handleWatch = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (onWatch) {
      onWatch();
    }
  };

  const handleDownload = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (onDownload) {
      onDownload();
    }
  };

  return (
    <TableRowItem verticalAlign={verticalAlign}>
      <div className={classes.wrapper}>
        {onWatch && (
          <LightTooltip disablePortal={false} title={onWatchTitle}>
            <IconButton
              onClick={handleWatch}
              size="small"
              className={classes.iconButton}
              disableTouchRipple
            >
              <RemoveRedEyeIcon className={classes.icon} />
            </IconButton>
          </LightTooltip>
        )}
        {onEdit && (
          <LightTooltip title={onEditTitle} disablePortal={false}>
            <IconButton
              onClick={handleEdite}
              size="small"
              className={classes.iconButton}
              disableTouchRipple
            >
              <EditIcon className={classes.icon} />
            </IconButton>
          </LightTooltip>
        )}
        {onDownload && (
          <LightTooltip title={onDownloadTitle} disablePortal={false}>
            <IconButton
              onClick={handleDownload}
              size="small"
              className={classes.iconButton}
              disableTouchRipple
            >
              <SaveAltIcon className={classes.icon} />
            </IconButton>
          </LightTooltip>
        )}
        {onDelete && (
          <LightTooltip title={onDeleteTitle} disablePortal={false}>
            <IconButton
              onClick={handleDelete}
              size="small"
              className={classes.iconButton}
              disableTouchRipple
            >
              <DeleteIcon className={classes.icon} />
            </IconButton>
          </LightTooltip>
        )}
      </div>
    </TableRowItem>
  );
};
