import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    borderRadius: '10px',
    outline: 'none',
    maxWidth: '750px'
  },
  body: {
    position: 'relative',
    padding: '40px 30px'
  },
  closeIcon: {
    position: 'absolute',
    right: '24px',
    top: '24px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    marginBottom: '10px'
  },
  titleIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    borderRadius: '100%',
    background: theme.palette.black['10']
  },
  bottom: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
    padding: '20px',
    borderTop: `1px solid ${theme.palette.black['10']}`
  },
  button: {
    minWidth: '140px'
  }
}));
