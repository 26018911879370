import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  footerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '30px 0'
  },
  footer: {
    width: '100%',
    maxWidth: '1920px'
  }
}));
