// eslint-disable-next-line import/no-mutable-exports
let env = { API_URL: 'https://brelok-crm.ru/api' };

if (process.env.ENV === 'dev') {
  env = {
    API_URL: 'https://pmp-zapstroy.ru/api'
  };
}
if (process.env.ENV === 'local') {
  env = {
    API_URL: 'http://45.146.167.164:3001/api'
  };
}
if (process.env.ENV === 'prod') {
  env = {
    API_URL: 'https://brelok-crm.ru/api'
  };
}

export default env;
