import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { isAvailableCatalog, isAvailableCatalogs } from 'widgets/layouts';
import { getUserInfo } from 'store/auth';
import { AccessesValuesType } from 'typings/accesses';
import { useStyles } from './NavigationItem.styles';

interface IProps {
  title: string;
  link?: string;
  id?: string;
  Icon: React.ElementType;
  subNavigation?: ISubNavigation[];
  dark?: boolean;
  onClick?: () => void;
  expanded?: string | false;
  setExpanded?: (data: false | string) => void;
  access?: AccessesValuesType;
}

interface ISubNavigation {
  title: string;
  link: string;
  accessKey: AccessesValuesType;
}

export const NavigationItem = ({
  title,
  link,
  Icon,
  subNavigation,
  dark,
  onClick,
  id,
  expanded,
  setExpanded,
  access
}: IProps) => {
  const { pathname } = useLocation();
  const user = useSelector(getUserInfo);
  const { classes, cx } = useStyles();

  useLayoutEffect(() => {
    const isAccordionNavItem = !!subNavigation;
    if (isAccordionNavItem && setExpanded && id) {
      subNavigation.forEach((item) => {
        if (pathname.includes(item.link)) setExpanded(id);
      });
    }
  }, []);

  const onChangeHandler =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (setExpanded) {
        setExpanded(isExpanded ? panel : false);
      }
    };
  const onClickLinkHandler = () => {
    onClick?.();
    setExpanded?.(id ?? false);
  };

  const isAvailableSection = isAvailableCatalog(user, access);
  if (!isAvailableSection && !subNavigation && access) {
    return null;
  }

  if (!subNavigation) {
    return (
      <Link
        onClick={onClickLinkHandler}
        to={link as string}
        className={cx(
          classes.accordionItem,
          classes.padding,
          classes.hovered,
          dark && classes.dark,
          pathname.includes(link as string) && classes.active
        )}
      >
        <Icon />
        <Typography variant="formMedium">{title}</Typography>
      </Link>
    );
  }

  if (subNavigation) {
    const isNotAvailableSubNavigation = !isAvailableCatalogs(
      user,
      subNavigation.map((item) => item.accessKey)
    );

    if (isNotAvailableSubNavigation) return null;
  }

  return (
    <Accordion
      classes={{ root: classes.accordionWrapper }}
      expanded={expanded === id}
      onChange={onChangeHandler(id ?? '')}
    >
      <AccordionSummary
        classes={{
          root: cx(classes.padding, classes.hovered),
          content: classes.accordionItem,
          expandIconWrapper: classes.accordionIcon
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Icon />
        <Typography variant="formMedium" className={classes.title}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        {subNavigation.map((item) => {
          const isNotAvailable = !isAvailableCatalog(user, item.accessKey);
          return (
            <Typography key={item.title} variant="formMedium">
              <Link
                to={item.link}
                className={cx(
                  classes.accordionSubItem,
                  classes.hovered,
                  pathname.includes(item.link) && classes.active,
                  isNotAvailable && classes.hidden
                )}
              >
                {item.title}
              </Link>
            </Typography>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
