import React from 'react';
import MUITableCell from '@mui/material/TableCell';
import { LightTooltip } from 'shared/ui';
import { useStyles } from './TableRowItem.styles';

interface IProps {
  children: React.ReactElement;
  width?: string;
  tooltipText?: string;
  verticalAlign?: string;
}

export const TableRowItem = ({ children, width, tooltipText, verticalAlign }: IProps) => {
  const { classes } = useStyles({ width, verticalAlign });
  if (!tooltipText) {
    return <MUITableCell classes={{ root: classes.bodyCell }}>{children}</MUITableCell>;
  }
  return (
    <MUITableCell classes={{ root: classes.bodyCell }}>
      <LightTooltip title={tooltipText} placement="bottom">
        {children}
      </LightTooltip>
    </MUITableCell>
  );
};
