import React from 'react';
import RatingMUI, { RatingProps } from '@mui/material/Rating';
import { Control, useController } from 'react-hook-form';
import Typography from '@mui/material/Typography';

interface IRatingProps extends RatingProps {
  name: string;
  control?: Control<any>;
  defaultValue?: number;
}

export const Rating = ({ name, control, defaultValue, readOnly }: IRatingProps) => {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue
  });

  return (
    <div>
      <RatingMUI name={name} value={value} onChange={onChange} readOnly={readOnly} />
      {error && (
        <Typography variant="formError" component="div" color="error.main">
          {error?.message}
        </Typography>
      )}
    </div>
  );
};
